<script>
import apiAds from "@/api/ads";

export default {
	name: "DonorCardEditDialog",


	props: {
		edited: {},
		info: {},
		types: {},
		typesLoaded: {},
		options: {},
		optionsLoaded: {},
	},


	data() {
		return {
			formLoading: false,
			fields: {},
			errors: {},
		}
	},


	created() {
		this.fields.type = this.info.type;
		this.fields.options = Array.from(new Set(this.info.options.map(el => el.id )));
		this.fields.is_limited = this.info.is_limited;
	},


	methods: {
		async handleUpdate() {
			this.errors = {};
			this.formLoading = true;
			try {
				const data = {
					type: this.fields.type,
					options: this.fields.options,
					is_limited: this.fields.is_limited,
				};
				const response = await apiAds.editDonor(this.info.id, data);
				this.$emit('update', response);
				this.$emit('close');
			} catch (error) {
				console.log(error)
				const errors = Object.keys(error.response.data);
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
				});
			} finally {
				this.formLoading = false;
			}
		},
	}
}
</script>

<template>
	<v-card class="pa-6">
		<v-form class="fill-height d-flex flex-column" @submit.prevent>
			<div class="text-body-1 mb-6">{{ info.name }}</div>
			<div class="mb-8">
				<div class="text-body-1 mb-3">Type</div>
				<v-select
						v-model="fields.type"
						:items="types"
						item-value="id"
						item-title="name"
						variant="outlined"
						density="compact"
						label="Type"
						hide-details
						:loading="!typesLoaded"
				>
				</v-select>
			</div>
			<div class="mb-8">
				<div class="text-body-1 mb-3">Options</div>
				<v-select
						v-model="fields.options"
						:items="options"
						item-value="id"
						item-title="name"
						variant="outlined"
						density="compact"
						label="Options"
						hide-details
						multiple
						:loading="!optionsLoaded"
				>
					<template v-slot:selection="{ index }">
						<span v-if="index === 0" class="text-grey text-caption align-self-center">Selected items: {{ fields.options.length }}</span>
					</template>
				</v-select>
			</div>
			<v-checkbox
					v-model="fields.is_limited"
					color="primary"
					label="Is limited"
			></v-checkbox>
			<v-row justify="end" no-gutters class="mt-4 pa-0">
				<v-btn color="primary" class="mr-2" variant="text" @click="$emit('close')">Cancel</v-btn>
				<v-btn color="primary" @click="handleUpdate">Save</v-btn>
			</v-row>
		</v-form>
	</v-card>
</template>

<style scoped>

</style>