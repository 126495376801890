<template>
	<v-card class="pa-6">
		<v-form class="fill-height d-flex flex-column" :disabled="formLoading" @submit.prevent>

			<div class="flex-0-0">
				<v-row justify="space-between">
					<v-col>
						<div class="text-h5 mb-4">
							<span>{{info.date}}</span>
							<span class="font-weight-medium ml-5">{{info.only_fans_model?.nickname}}</span>
						</div>
						<div class="mb-2 d-flex align-center"><span>id: {{ info.ads_number }}</span> <v-icon v-if="isBlocked" color="grey">mdi-lock</v-icon></div>
						<div v-if="info.is_parent" class="mb-2 d-flex align-center">
							<v-btn v-if="adByIdLoaded" @click="linkToPromo" variant="text" prepend-icon="mdi-link-variant">Child promo: id {{ info.child.ads_number }}</v-btn>
							<v-skeleton-loader v-else type="text" width="200"></v-skeleton-loader>
						</div>
						<div v-if="info.is_child" class="mb-2 d-flex align-center">
							<v-btn v-if="adByIdLoaded" @click="linkToPromo" variant="text" prepend-icon="mdi-link-variant">Parent promo: id {{ info.parent.ads_number }}</v-btn>
							<v-skeleton-loader v-else type="text" width="200"></v-skeleton-loader>
						</div>
					</v-col>
					<v-col cols="auto" v-if="edited">
						<v-btn v-if="!info.is_child" :disabled="info.fans_delta <= 0 || info.is_parent" class="mr-2" @click="dialogSwapOpened = true" variant="text" size="x-large" density="compact" icon="mdi-swap-horizontal"></v-btn>
						<v-btn v-if="!info.is_child" class="mr-2" @click="dialogCopyPromo = true" variant="text" size="x-large" density="compact" icon="mdi-content-copy"></v-btn>
						<v-btn @click="dialogDeletePromo = true" variant="text" size="x-large" density="compact" icon="mdi-delete"></v-btn>
					</v-col>
				</v-row>
				<v-divider></v-divider>
			</div>

			<div class="flex-1-1 overflow-y-auto py-4 px-3 mx-n3">

				<v-stepper editable class="elevation-0" v-model="fields.status">
					<v-stepper-header>
						<v-stepper-item
								value="draft"
								color="white"
								title="Черновик"
								edit-icon="mdi-pencil"
						></v-stepper-item>
						<v-divider></v-divider>
						<v-stepper-item
								value="progress"
								color="amber-lighten-4"
								title="В процессе"
								edit-icon="mdi-play"
						></v-stepper-item>
						<v-divider></v-divider>
						<v-stepper-item
								value="done"
								color="green-lighten-4"
								title="Готово"
								edit-icon="mdi-check"
						></v-stepper-item>
						<v-divider></v-divider>
						<v-stepper-item
								value="other_type"
								color="indigo-lighten-4"
								title="Другой тип рекламы"
								edit-icon="mdi-advertisements"
						></v-stepper-item>
						<v-divider></v-divider>
						<v-stepper-item
								value="swap"
								color="purple-lighten-4"
								title="Перелив/swap"
								edit-icon="mdi-swap-horizontal"
						></v-stepper-item>
						<v-divider></v-divider>
						<v-stepper-item
								value="problem"
								color="red-lighten-4"
								title="Проблема"
								edit-icon="mdi-exclamation-thick"
						></v-stepper-item>
					</v-stepper-header>
				</v-stepper>

				<template v-if="info.is_child">

					<div class="mb-6">
						<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Link info</div>
						<v-row class="flex-wrap">
							<v-col :cols="mdAndDown ? 12 : 6">
								<div class="text-body-1 mb-2">Linked used</div>
								<v-text-field
										:disabled="isBlocked"
										variant="outlined"
										placeholder="Linked used"
										v-model="fields.trial_link"
										:error-messages="errors.trial_link"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2 text-grey">Our model</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Our model"
										:model-value="info.only_fans_model?.nickname"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">OF link Date</div>
								<VueDatePicker
										:disabled="isBlocked"
										position="right"
										class="clearable-false"
										v-model="fields.of_link_date"
										auto-apply
										:format="format"
										clearable
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Model donor</div>
								<SelectDonor
										disabled
										readonly
										v-model="fields.donor"
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">Date end</div>
								<VueDatePicker
										position="right"
										v-model="fields.date_end"
										auto-apply
										:format="format"
										clearable
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">Reserve link</div>
								<v-text-field
										variant="outlined"
										placeholder="Reserve link"
										v-model="fields.reserve_link"
										:error-messages="errors.reserve_link"
								></v-text-field>
							</v-col>
						</v-row>
					</div>

					<v-divider class="my-6"></v-divider>

					<div class="mb-6">
						<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Child promo metrics</div>
						<v-row class="flex-wrap">
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Cost</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Cost"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.cost"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Cost without VAT and fee</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Cost without VAT and fee"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.cwvt"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Cost per fan</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Cost per fan"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.cost_per_fan"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Phantom cost</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Phantom cost"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.phantom_cost"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Claims count</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Claims count"
										v-model="fields.claims_count"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Fans</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Fans"
										v-model="fields.fans_count"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Revenue</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Revenue"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.revenue"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Profit</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Profit"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.profit"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2">APRU</div>
								<v-text-field
										variant="outlined"
										placeholder="APRU"
										v-model="fields.apru"
										prepend-inner-icon="mdi-currency-usd"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">ROMI</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="ROMI"
										prepend-inner-icon="mdi-percent-outline"
										v-model="fields.romi"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Spenders</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Spenders"
										v-model="fields.spenders"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Fans delta</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Fans delta"
										v-model="fields.fans_delta"
										hide-details
								></v-text-field>
							</v-col>
						</v-row>
					</div>

					<v-divider class="my-6"></v-divider>

				</template>

				<template v-else-if="info.is_parent">

					<div class="mb-6">
						<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Link info</div>
						<v-row class="flex-wrap">
							<v-col :cols="mdAndDown ? 12 : 7">
								<div class="text-body-1 mb-2">Linked used</div>
								<v-text-field
										:disabled="isBlocked"
										variant="outlined"
										placeholder="Linked used"
										v-model="fields.trial_link"
										:error-messages="errors.trial_link"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2 text-grey">Our model</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Our model"
										:model-value="info.only_fans_model?.nickname"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 2">
								<div class="text-body-1 mb-2">Cost</div>
								<v-text-field
										:disabled="isBlocked"
										variant="outlined"
										placeholder="Cost"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.cost"
										:error-messages="errors.cost"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2">Buy date</div>
								<VueDatePicker
										:disabled="isBlocked"
										position="right"
										class="clearable-false"
										v-model="fields.buy_date"
										auto-apply
										:format="format"
										clearable
								/>
<!--								:min-date="minDateBuy"-->
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2">Model donor</div>
								<SelectDonor
										:disabled="isBlocked && (userInfo.role !== 'hom' && userInfo.role !== 'marketer')"
										v-model="fields.donor"
										creation
										:return-object="false"
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2">OF link Date</div>
								<VueDatePicker
										:disabled="isBlocked"
										position="right"
										class="clearable-false"
										v-model="fields.of_link_date"
										auto-apply
										:format="format"
										clearable
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">Date end</div>
								<VueDatePicker
										position="right"
										v-model="fields.date_end"
										auto-apply
										:format="format"
										clearable
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">Reserve link</div>
								<v-text-field
										variant="outlined"
										placeholder="Reserve link"
										v-model="fields.reserve_link"
										:error-messages="errors.reserve_link"
								></v-text-field>
							</v-col>
						</v-row>
					</div>

					<v-divider class="my-6"></v-divider>

					<div class="mb-6">
						<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Parent promo metrics</div>
						<v-row class="flex-wrap">
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Cost</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Cost"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.cost"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Cost without VAT and fee</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Cost without VAT and fee"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.cwvt"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Cost per fan</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Cost per fan"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.cost_per_fan"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Phantom cost</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Phantom cost"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.phantom_cost"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Claims count</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Claims count"
										v-model="fields.claims_count"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Fans</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Fans"
										v-model="fields.fans_count"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Revenue</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Revenue"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.revenue"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Profit</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Profit"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.profit"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">APRU</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="APRU"
										v-model="fields.apru"
										prepend-inner-icon="mdi-currency-usd"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">ROMI</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="ROMI"
										prepend-inner-icon="mdi-percent-outline"
										v-model="fields.romi"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Spenders</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Spenders"
										v-model="fields.spenders"
										hide-details
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Fans delta</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Fans delta"
										v-model="fields.fans_delta"
										hide-details
								></v-text-field>
							</v-col>
						</v-row>
					</div>

					<v-divider class="my-6"></v-divider>

					<div class="my-6">
						<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Payment info</div>
						<v-row class="flex-wrap">
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2">Payment</div>
								<v-text-field
										:disabled="isBlocked"
										variant="outlined"
										v-model="fields.payment"
										placeholder="Payment"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2">Payment method</div>
								<SelectPaymentMethod
										:disabled="isBlocked"
										v-model="fields.payment_method"
										clearable
										:return-object="false"
										creation
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2">Platform/Type</div>
								<SelectPlatformType
										:disabled="isBlocked"
										v-model="fields.platform_type"
										clearable
										:return-object="false"
										creation
								/>
							</v-col>
						</v-row>
					</div>

					<v-divider></v-divider>

				</template>

				<template v-else>
					<div class="mb-6">
						<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Link info</div>
						<v-row class="flex-wrap">
							<v-col :cols="mdAndDown ? 12 : 7">
								<div class="text-body-1 mb-2">Linked used</div>
								<v-text-field
										:disabled="isBlocked"
										variant="outlined"
										placeholder="Linked used"
										v-model="fields.trial_link"
										:error-messages="errors.trial_link"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 2">
								<div class="text-body-1 mb-2">Cost</div>
								<v-text-field
										:disabled="isBlocked"
										variant="outlined"
										placeholder="Cost"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.cost"
										:error-messages="errors.cost"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">Buy date</div>
								<VueDatePicker
										:disabled="isBlocked"
										position="right"
										class="clearable-false"
										v-model="fields.buy_date"
										auto-apply
										:format="format"
										clearable
								/>
<!--								:min-date="minDateBuy"-->
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2">Model donor</div>
								<SelectDonor
										:disabled="isBlocked && (userInfo.role !== 'hom' && userInfo.role !== 'marketer')"
										v-model="fields.donor"
										creation
										:return-object="false"
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 5">
								<div class="text-body-1 mb-2">OF link</div>
								<v-text-field
										:disabled="isBlocked"
										variant="outlined"
										placeholder="OF link"
										v-model="fields.of_link"
										:error-messages="errors.of_link"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">OF link Date</div>
								<VueDatePicker
										:disabled="isBlocked"
										position="right"
										class="clearable-false"
										v-model="fields.of_link_date"
										auto-apply
										:format="format"
										clearable
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">Inner model</div>
								<SelectModel
										v-model="fields.inner_model"
										:items="commonListModels"
										:loading="!commonListModelsLoaded"
										:disabled="!commonListModelsLoaded"
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">Inner traffic</div>
								<v-checkbox
										v-model="fields.inner_traffic"
										label="Enable mode"
								></v-checkbox>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">Date end</div>
								<VueDatePicker
										position="right"
										v-model="fields.date_end"
										auto-apply
										:format="format"
										clearable
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">Reserve link</div>
								<v-text-field
										variant="outlined"
										placeholder="Reserve link"
										v-model="fields.reserve_link"
										:error-messages="errors.reserve_link"
								></v-text-field>
							</v-col>
						</v-row>
					</div>

					<v-divider></v-divider>

					<div class="my-6">
						<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Result</div>
						<v-row class="flex-wrap">
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2 text-grey">Revenue</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Revenue"
										v-model="fields.revenue"
										prepend-inner-icon="mdi-currency-usd"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2 text-grey">Profit</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Profit"
										v-model="fields.profit"
										prepend-inner-icon="mdi-currency-usd"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">APRU</div>
								<v-text-field
										variant="outlined"
										placeholder="APRU"
										v-model="fields.apru"
										prepend-inner-icon="mdi-currency-usd"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2 text-grey">ROMI</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="ROMI"
										v-model="fields.romi"
										prepend-inner-icon="mdi-percent-outline"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2 text-grey">Cost result</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Cost result"
										v-model="fields.cost_result"
										prepend-inner-icon="mdi-currency-usd"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2 text-grey">Claims count</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										v-model="fields.claims_count"
										placeholder="Claims count"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 2">
								<div class="text-body-1 mb-2 text-grey">Spenders</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										v-model="fields.spenders"
										placeholder="Spenders"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 2">
								<div class="text-body-1 mb-2">Fans</div>
								<v-text-field
										:disabled="isBlocked"
										variant="outlined"
										v-model="fields.fans_count"
										placeholder="Fans"
										:error-messages="errors.fans_count"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 2">
								<div class="text-body-1 mb-2 text-grey">Fans delta</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										v-model="fields.fans_delta"
										placeholder="Fans delta"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 3">
								<div class="text-body-1 mb-2">Cost without VAT and fee</div>
								<v-text-field
										:disabled="isBlocked"
										variant="outlined"
										v-model="fields.cwvt"
										placeholder="Cost without VAT and fee"
										prepend-inner-icon="mdi-currency-usd"
										:error-messages="errors.cwvt"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2 text-grey">Cost per fan</div>
								<v-text-field
										disabled
										readonly
										variant="outlined"
										placeholder="Cost per fan"
										prepend-inner-icon="mdi-currency-usd"
										v-model="fields.cost_per_fan"
										hide-details
								></v-text-field>
							</v-col>
						</v-row>
					</div>

					<v-divider></v-divider>

					<div class="my-6">
						<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Payment info</div>
						<v-row class="flex-wrap">
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2">Payment</div>
								<v-text-field
										:disabled="isBlocked"
										variant="outlined"
										v-model="fields.payment"
										placeholder="Payment"
								></v-text-field>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2">Payment method</div>
								<SelectPaymentMethod
										:disabled="isBlocked"
										v-model="fields.payment_method"
										clearable
										:return-object="false"
										creation
								/>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 4">
								<div class="text-body-1 mb-2">Platform/Type</div>
								<SelectPlatformType
										:disabled="isBlocked"
										v-model="fields.platform_type"
										clearable
										:return-object="false"
										creation
								/>
							</v-col>
						</v-row>
					</div>

					<v-divider></v-divider>

				</template>

				<div class="my-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Other</div>
					<v-row class="flex-wrap">
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2">Buyer</div>
							<SelectMarketer v-model="fields.marketer" :selected="info.marketer" clearable/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2">Promo</div>
							<SelectPromo
									:disabled="isBlocked"
									v-model="fields.promo"
									clearable
									creation
									:return-object="false"
							/>
						</v-col>
						<v-col cols="12">
							<div class="text-body-1 mb-2">Comments</div>
							<v-textarea
									variant="outlined"
									v-model="fields.comment"
									placeholder="Some comment here"
							></v-textarea>
						</v-col>
					</v-row>
				</div>

<!--
				<v-divider v-if="edited && !info.is_parent"></v-divider>

					<div class="my-6" v-if="edited && !info.is_parent">
-->
				<v-divider v-if="edited"></v-divider>

				<div class="my-6" v-if="edited">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Location</div>
					<v-alert v-if="errorsLocation" type="error" class="mb-3">{{errorsLocation}}</v-alert>
					<v-row class="flex-wrap">
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2">Date</div>
							<VueDatePicker
									:disabled="isBlocked"
									position="right"
									class="clearable-false"
									v-model="fields.date"
									auto-apply
									:format="format"
									:clearable="false"
									:state="(errorsLocation.length > 0) ? !(errorsLocation) : null"
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2">Our model</div>
							<SelectModel
									v-model="fields.only_fans_model"
									:items="commonListModels"
									:loading="!commonListModelsLoaded"
									:disabled="!commonListModelsLoaded || isBlocked"
									:error-messages="errorsLocation"
							/>
						</v-col>
					</v-row>
				</div>

				<v-divider v-if="edited && !info.is_child && !info.is_parent"></v-divider>

				<div class="mt-6" v-if="edited && !info.is_child && !info.is_parent">
					<v-expansion-panels>
						<v-expansion-panel>
							<v-expansion-panel-title>Refund</v-expansion-panel-title>
							<v-expansion-panel-text>
								<v-row class="flex-wrap">
									<v-col :cols="mdAndDown ? 12 : 3">
										<div class="text-body-1 mb-2">Refund date</div>
<!--										<VueDatePicker-->
<!--												position="left"-->
<!--												:format="format"-->
<!--												auto-apply-->
<!--												clearable-->
<!--												v-model="fields.refund_date"-->
<!--												:min-date="minDateRefund"-->
<!--										/>-->
										<VueDatePicker
												position="left"
												:format="format"
												auto-apply
												clearable
												v-model="fields.refund_date"
										/>
									</v-col>
									<v-col :cols="mdAndDown ? 12 : 3">
										<div class="text-body-1 mb-2">Refund cost</div>
										<v-text-field
												variant="outlined"
												placeholder="Enter refund cost"
												v-model="fields.refund_cost"
												prepend-inner-icon="mdi-currency-usd"
												:error-messages="errors.refund_cost"
										></v-text-field>
									</v-col>
									<v-col :cols="mdAndDown ? 12 : 3">
										<div class="text-body-1 mb-2">New fans count</div>
										<v-text-field
												variant="outlined"
												placeholder="New fans count"
												v-model="fields.new_fans_count"
												:error-messages="errors.new_fans_count"
										></v-text-field>
									</v-col>
									<v-col :cols="mdAndDown ? 12 : 3">
										<div class="text-body-1 mb-2">Refund payment</div>
										<v-text-field
												variant="outlined"
												placeholder="Details for which the return was issued"
												v-model="fields.refund_payment"
												:error-messages="errors.refund_payment"
										></v-text-field>
									</v-col>
								</v-row>
							</v-expansion-panel-text>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>

			</div>

			<div class="flex-0-0">
				<v-divider></v-divider>
				<v-row justify="start" no-gutters class="mt-4 pa-0">
					<v-btn v-if="edited" class="mr-2" :loading="formLoading" type="submit" color="primary" @click="toMove ? openDialogMovePromo() : editPromo()">Сохранить</v-btn>
					<v-btn v-else class="mr-2" :loading="formLoading" type="submit" color="primary" @click="addPromo">Добавить</v-btn>
					<v-btn variant="text" @click="$emit('close')">Отмена</v-btn>
				</v-row>
			</div>

			<v-dialog v-model="dialogCopyPromo" max-width="680px" fullscreen persistent transition="slide-x-transition" class="right-position">
				<PromoCopyDialog
						:info="info"
						@close="dialogCopyPromo = false"
						@copy="addCopy"
				/>
			</v-dialog>

			<v-dialog v-model="dialogDeletePromo" max-width="340px">
				<v-card :disabled="formLoading" class="pa-6">
					<template v-if="info.is_parent">
						<div class="text-body-1 mb-2">Невозможно удалить родительскую рекламную компанию. Сначала удалите дочернюю.</div>
					</template>
					<template v-else>
						<div class="text-body-1 mb-2">Удалить рекламную компанию</div>
						<div class="text-subtitle-2">Вы уверены?</div>
					</template>
					<v-row justify="end" no-gutters class="mt-10 pa-0">
						<v-btn color="primary" class="mr-2" variant="text" @click="dialogDeletePromo = false">Отмена</v-btn>
						<v-btn v-if="info.is_parent === false" :loading="deleteLoading" color="primary" @click="deletePromo">Подтвердить</v-btn>
					</v-row>
				</v-card>
			</v-dialog>

			<v-dialog v-model="dialogMovePromo" max-width="500px">
				<v-card class="pa-6">
					<div class="text-h5 mb-8 text-center">Переместить рекламную компанию</div>
					<template v-if="changedDate">
						<div class="text-body-1 text-center mb-2">Изменена дата:</div>
						<div class="d-flex align-center justify-center mb-6">
							<span class="text-body-1">{{ info.date }}</span>
							<v-icon class="mx-2" size="20">mdi-arrow-right</v-icon>
							<span class="text-body-1 font-weight-bold text-primary">{{ formatingDate(fields.date, 'yyyy-MM-dd') }}</span>
						</div>
					</template>
					<template v-if="changedModel">
						<div class="text-body-1 text-center mb-2">Изменена модель:</div>
						<div class="d-flex align-center justify-center">
							<span class="text-body-1">{{ info.only_fans_model.nickname }}</span>
							<v-icon class="mx-2" size="20">mdi-arrow-right</v-icon>
							<span class="text-body-1 font-weight-bold text-primary">{{ getModelObjectById(fields.only_fans_model).nickname }}</span>
						</div>
					</template>
					<v-row justify="end" no-gutters class="mt-10 pa-0">
						<v-btn color="primary" class="mr-2" variant="text" @click="dialogMovePromo = false">Отмена</v-btn>
						<v-btn color="primary" @click="editPromo">Подтвердить</v-btn>
					</v-row>
				</v-card>
			</v-dialog>

			<v-dialog v-model="dialogSwapOpened" fullscreen persistent transition="slide-x-transition" max-width="1000px" class="right-position">
				<PromoSwapDialog
						:info="info"
						@swap="applySwap"
						@close="dialogSwapOpened = false"
				/>
			</v-dialog>

		</v-form>
	</v-card>
</template>

<script>

  import apiAds from "@/api/ads";
	import SelectDonor from "@/components/SelectDonor.vue";
	import SelectMarketer from "@/components/SelectMarketer.vue";
	import SelectPaymentMethod from "@/components/SelectPaymentMethod.vue";
	import SelectPlatformType from "@/components/SelectPlatformType.vue";
	import SelectPromo from "@/components/SelectPromo.vue";
	import {useDisplay} from "vuetify";
	import SelectModel from "@/components/SelectModel.vue";
	import {mapState} from "vuex";
	import {toast} from "vuetify-sonner";
	import PromoCopyDialog from "@/components/PromoCopyDialog.vue";
	import PromoSwapDialog from "@/components/PromoSwapDialog.vue";

	export default {
		setup () {
			// Destructure only the keys we want to use
			const { lgAndUp, mdAndDown } = useDisplay();
			return { lgAndUp, mdAndDown }
		},


    name: 'PromoEditDialog',


		components: {
			PromoSwapDialog,
			PromoCopyDialog,
			SelectModel,
			SelectPromo,
			SelectPlatformType,
			SelectPaymentMethod,
			SelectMarketer,
			SelectDonor
		},


    props: {
      info: {
        type: Object,
      }
    },


    data() {
      return {
        isBlocked: true,
        formLoading: false,
				adById: null,
				adByIdLoaded: false,
				deleteLoading: false,
        format: 'dd.MM.yyyy',
        fields: {},
				dialogCopyPromo: false,
				dialogDeletePromo: false,
				dialogMovePromo: false,
				dialogSwapOpened: false,
				errorsLocation: '',
				errors: {},
				checkListFields: ['trial_link', 'buy_date', 'cost', 'payment', 'payment_method', 'platform_type', 'donor', 'promo', 'fans_count', 'fans_delta', 'cwvt', 'comment', 'of_link_date', 'of_link', 'marketer', 'profit', 'romi', 'refund_date', 'refund_cost', 'new_fans_count', 'refund_payment'],
      }
    },


		computed: {
			...mapState({
				commonListModels: state => state.user.commonListModels,
				commonListModelsLoaded: state => state.user.commonListModelsLoaded,
				userInfo: state => state.user.info,
				userLoading: state => state.user.userLoading,
				userLoaded: state => state.user.userLoaded,
			}),
			edited() {
				if (this.info.id) {
					return true
				} else {
					return false
				}
			},
			changedDate() {
				if (this.info.id) {
					const resetDateFormat = new Date(this.fields.date);
					const newDate = this.formatingDate(resetDateFormat, 'yyyy-MM-dd');
					return this.info.date !== newDate
				} else {
					return false
				}
			},
			changedModel() {
				if (this.info.id) {
					return this.info.only_fans_model.id !== this.fields.only_fans_model
				} else {
					return false
				}
			},
			toMove() {
				return this.changedDate || this.changedModel
			},
			minDateBuy() {
				// if (this.info.buy_date === null) {
				// 	const now = new Date();
				// 	now.setDate(new Date().getDate() - 30);
				// 	return now
				// } else {
				// 	return ''
				// }
				const now = new Date();
				now.setDate(new Date().getDate() - 30);
				return now
			},
			minDateRefund() {
				const now = new Date();
				now.setDate(new Date().getDate() - 30);
				return now
			},
			maxDate() {
				const now = new Date();
				now.setDate(new Date().getDate() + 30);
				return now
			},
		},


		created() {
			if (this.info.is_blocked) {
				this.isBlocked = true
			} else {
				this.isBlocked = false
			}
			this.setFields();
			if (this.info.is_parent) {
				this.setRelativeAd(this.info.child.id);
			}
			if (this.info.is_child) {
				this.setRelativeAd(this.info.parent.id);
			}
		},


		watch: {
			info() {
				if (JSON.stringify(this.info) !== '{}') {
					if (this.info.is_blocked) {
						this.isBlocked = true
					} else {
						this.isBlocked = false
					}
					this.setFields();
					if (this.info.is_parent) {
						this.setRelativeAd(this.info.child.id);
					}
					if (this.info.is_child) {
						this.setRelativeAd(this.info.parent.id);
					}
				}
			},
			fields: {
				handler() {
					this.errorsLocation = ''
				},
				deep: true,
			}
		},


    methods: {
			openDialogMovePromo() {
				this.dialogMovePromo = true
			},

			formatingDate(date, format) {
				const year = date.getFullYear();
				const month = (date.getMonth() + 1).toString().padStart(2, '0');
				const day = (date.getDate().toString().padStart(2, '0'));
				if (format === 'dd.MM.yyyy') {
					return `${day}.${month}.${year}`;
				} else if (format === 'yyyy-MM-dd') {
					return `${year}-${month}-${day}`;
				}
			},

			getModelObjectById(id) {
				return this.$store.getters['user/getModelObjectById'](id);
			},

			checkForEmptyPromo() {
				let empty = true;
				this.checkListFields.forEach((item) => {
					if (this.fields[item]) {
						if (JSON.stringify(this.fields[item]).length > 0) {
							empty = false
						}
					}
				});
				return empty
			},

      setFields() {
        this.fields.date = this.info.date;
        this.fields.trial_link = this.info.trial_link;
        if (this.info.buy_date) { this.fields.buy_date = new Date(this.info.buy_date)} else { this.fields.buy_date = null }
        this.fields.cost = this.info.cost;
        this.fields.phantom_cost = this.info.phantom_cost;
        this.fields.payment = this.info.payment;
        this.fields.only_fans_model = this.info.only_fans_model.id;
				if (this.info.inner_model) { this.fields.inner_model = this.info.inner_model.id } else { this.fields.inner_model = null }
				this.fields.inner_traffic = this.info.inner_traffic;
				if (this.info.payment_method) { this.fields.payment_method = this.info.payment_method.name } else { this.fields.payment_method = null }
				if (this.info.platform_type) { this.fields.platform_type = this.info.platform_type.name } else { this.fields.platform_type = null }
				if (this.info.donor) { this.fields.donor = this.info.donor.name } else { this.fields.donor = null }
				if (this.info.promo) { this.fields.promo = this.info.promo.name } else { this.fields.promo = null }
        this.fields.fans_count = this.info.fans_count;
        this.fields.fans_delta = this.info.fans_delta;
        this.fields.cwvt = this.info.cwvt;
        this.fields.cost_per_fan = this.info.cost_per_fan;
        this.fields.comment = this.info.comment;
				if (this.info.of_link_date) { this.fields.of_link_date = new Date(this.info.of_link_date) } else { this.fields.of_link_date = null }
				if (this.info.of_link) { this.fields.of_link = this.info.of_link} else { this.fields.of_link = null }
				this.fields.date_end = this.info.date_end;
				this.fields.reserve_link = this.info.reserve_link;
        if (this.info.marketer) { this.fields.marketer = this.info.marketer.id } else { this.fields.marketer = null }
        this.fields.status = this.info.status.name;
        this.fields.revenue = this.info.revenue;
        this.fields.profit = this.info.profit;
        this.fields.apru = this.info.apru;
        this.fields.romi = this.info.romi;
        if (this.info.cost_result) { this.fields.cost_result = this.info.cost_result } else { this.fields.cost_result = null }
        this.fields.claims_count = this.info.claims_count;
        this.fields.spenders = this.info.spenders;
        if (this.info.refund_date) { this.fields.refund_date = new Date(this.info.refund_date) } else { this.fields.refund_date = null }
        this.fields.refund_cost = this.info.refund_cost;
        this.fields.new_fans_count = this.info.new_fans_count;
        this.fields.refund_payment = this.info.refund_payment;
      },

			async getAdById(id) {
				try {
					const response = await apiAds.getAdById(id);
					return response;
				} catch (error) {
					console.log(error)
				}
			},

			async setRelativeAd(id) {
				this.adByIdLoaded = false;
				const response = await this.getAdById(id);
				this.adById = response;
				this.adByIdLoaded = true;
			},

			linkToPromo() {
				this.$emit('linkToPromo', this.adById);
			},

			async deletePromo() {
				this.deleteLoading = true;
				try {
					await apiAds.deletePromo(this.info.id);
					if (this.info.is_child) {
						const response = await this.getAdById(this.info.parent.id);
						// this.$emit('linkToPromo', response);
						this.$emit('edit', {response, old_date: this.info.date, old_model: this.info.only_fans_model.id, is_blocked: this.info.is_blocked})
					}
					this.$emit('delete', {id: this.info.id, old_date: this.info.date});
					this.$emit('close');
					this.deleteLoading = false;
					this.dialogDeletePromo = false
				} catch (error) {
					console.log(error)
				}
			},

			async addPromo() {
				let date_end = null;
				let buy_date = null;
				let of_link = null;
				let of_link_date = null;
				if (this.fields.date_end) { date_end = this.formatingDate(new Date(this.fields.date_end), 'yyyy-MM-dd') }
				if (this.fields.buy_date) { buy_date = this.formatingDate(this.fields.buy_date, 'yyyy-MM-dd') }
				if (this.fields.of_link_date) { of_link_date = this.formatingDate(this.fields.of_link_date, 'yyyy-MM-dd') }
				if (this.fields.of_link) { of_link = this.fields.of_link }
				if (this.fields.fans_count === '') { this.fields.fans_count = null }
				if (this.fields.cost === '') { this.fields.cost = null }
				if (this.checkForEmptyPromo()) {
					toast('Внимание:', {
						duration: 5000,
						description: 'Как минимум одно поле должно быть заполнено',
						cardProps: {
							color: 'warning',
						},
					});
				} else {
					try {
						this.formLoading = true;
						const response = await apiAds.addPromo({
							date: this.fields.date,
							status: this.fields.status,
							trial_link: this.fields.trial_link,
							only_fans_model: this.fields.only_fans_model,
							cost: this.fields.cost,
							buy_date: buy_date,
							donor: this.fields.donor,
							of_link: of_link,
							of_link_date: of_link_date,
							date_end: date_end,
							reserve_link: this.fields.reserve_link,
							apru: this.fields.apru,
							fans_count: this.fields.fans_count,
							cwvt: this.fields.cwvt,
							payment: this.fields.payment,
							payment_method: this.fields.payment_method,
							platform_type: this.fields.platform_type,
							marketer: this.fields.marketer,
							promo: this.fields.promo,
							comment: this.fields.comment,
						})
						this.$emit('add', {response})
						this.$emit('close')
					} catch (error) {
						console.log('error', error);
						const errors = Object.keys(error.response.data);
						errors.forEach((key) => {
							this.errors[key] = error.response.data[key]
						});
						toast('Ошибка:', {
							duration: 5000,
							description: 'Не удалось сохранить изменения в рекламной компании. Проверьте правильность заполнения полей',
							cardProps: {
								color: 'error',
							},
						});
					} finally {
						this.formLoading = false;
					}
				}
			},

			async editPromo() {
				this.errorsLocation = '';
				this.errors = {};
				this.dialogMovePromo = false;
				let date_end = null;
				let buy_date = null;
				let of_link = null;
				let of_link_date = null;
				let donor = null;
				let refund_date = null;
				let refund_cost = null;
				let new_fans_count = null;
				let refund_payment = null;
				let resetFormatDate = new Date(this.fields.date);
				let date = this.formatingDate(resetFormatDate, 'yyyy-MM-dd');
				if (this.fields.date_end) { date_end = this.formatingDate(new Date(this.fields.date_end), 'yyyy-MM-dd') }
				if (this.fields.buy_date) { buy_date = this.formatingDate(this.fields.buy_date, 'yyyy-MM-dd') }
				if (this.fields.of_link_date) { of_link_date = this.formatingDate(this.fields.of_link_date, 'yyyy-MM-dd') }
				if (this.fields.of_link) { of_link = this.fields.of_link }
				if (this.fields.fans_count === '') { this.fields.fans_count = null }
				if (this.fields.cost === '') { this.fields.cost = null }
				if (this.fields.donor) { donor = this.fields.donor }
				if (this.fields.refund_date) { refund_date = this.formatingDate(this.fields.refund_date, 'yyyy-MM-dd') }
				if (this.fields.refund_cost) { refund_cost = this.fields.refund_cost }
				if (this.fields.new_fans_count) { new_fans_count = this.fields.new_fans_count }
				if (this.fields.refund_payment) { refund_payment = this.fields.refund_payment }
				if (this.checkForEmptyPromo()) {
					this.deletePromo()
				} else {
					try {
						this.formLoading = true;
						let response = null;
						let params = {};
						if (this.info.is_child) {
							params = {
								date: date,
								only_fans_model: this.fields.only_fans_model,
								status: this.fields.status,
								trial_link: this.fields.trial_link,
								of_link_date: of_link_date,
								date_end: date_end,
								reserve_link: this.fields.reserve_link,
								marketer: this.fields.marketer,
								promo: this.fields.promo,
								comment: this.fields.comment,
							}
						} else if (this.info.is_parent) {
							params = {
								date: date,
								only_fans_model: this.fields.only_fans_model,
								status: this.fields.status,
								trial_link: this.fields.trial_link,
								cost: this.fields.cost,
								buy_date: buy_date,
								donor: donor,
								of_link_date: of_link_date,
								date_end: date_end,
								reserve_link: this.fields.reserve_link,
								payment: this.fields.payment,
								payment_method: this.fields.payment_method,
								platform_type: this.fields.platform_type,
								marketer: this.fields.marketer,
								promo: this.fields.promo,
								comment: this.fields.comment,
							}
						} else {
							params = {
								date: date,
								only_fans_model: this.fields.only_fans_model,
								inner_traffic: this.fields.inner_traffic,
								inner_model: this.fields.inner_model,
								status: this.fields.status,
								trial_link: this.fields.trial_link,
								cost: this.fields.cost,
								buy_date: buy_date,
								donor: donor,
								of_link: of_link,
								of_link_date: of_link_date,
								date_end: date_end,
								reserve_link: this.fields.reserve_link,
								apru: this.fields.apru,
								fans_count: this.fields.fans_count,
								cwvt: this.fields.cwvt,
								payment: this.fields.payment,
								payment_method: this.fields.payment_method,
								platform_type: this.fields.platform_type,
								marketer: this.fields.marketer,
								promo: this.fields.promo,
								comment: this.fields.comment,
								refund_date: refund_date,
								refund_cost: refund_cost,
								new_fans_count: new_fans_count,
								refund_payment: refund_payment,
							}
						}

						if (refund_cost && (Number(this.fields.cost) === 0 || Number(this.fields.cost) === 1)) {
							this.errors.refund_cost = '"Refund" недоступен при значении поля "Cost" 0 или 1'
							toast('Ошибка:', {
								duration: 5000,
								description: 'Не удалось сохранить изменения в рекламной компании. Проверьте правильность заполнения полей.' + JSON.stringify(this.errors),
								cardProps: {
									color: 'error',
								},
							});
						} else if (refund_cost && Number(refund_cost) > Number(this.fields.cost)) {
							this.errors.refund_cost = 'Значение "Refund cost" не должно превышать поле "Cost"'
							toast('Ошибка:', {
								duration: 5000,
								description: 'Не удалось сохранить изменения в рекламной компании. Проверьте правильность заполнения полей.' + JSON.stringify(this.errors),
								cardProps: {
									color: 'error',
								},
							});
						} else {
							response = await apiAds.editPromo(this.info.id, params);
							this.$emit('edit', {response, old_date: this.info.date, old_model: this.info.only_fans_model.id, is_blocked: this.info.is_blocked})
							this.$emit('close')
						}


					} catch (error) {
						console.log(error)
						if (error.response.data.location) {
							this.errorsLocation = 'Выбранная дата для данной модели уже занята'
						}
						const errors = Object.keys(error.response.data);
						errors.forEach((key) => {
							this.errors[key] = error.response.data[key]
						});
						toast('Ошибка:', {
							duration: 5000,
							description: 'Не удалось сохранить изменения в рекламной компании. Проверьте правильность заполнения полей.' + JSON.stringify(this.errors),
							cardProps: {
								color: 'error',
							},
						});
					} finally {
						this.formLoading = false;
					}
				}
			},

			applySwap(response) {
				this.$emit('swap', response);
			},

			addCopy(response) {
				this.$emit('copy', response)
			}
    }
  }
</script>

<style scoped>
.v-theme--light .v-stepper-item {
	--v-medium-emphasis-opacity: 0.35;
}
</style>