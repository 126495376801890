<script>
import {useDisplay} from "vuetify";
import apiFinance from "@/api/finance";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { xlAndUp, mdAndDown } = useDisplay();
		return { xlAndUp, mdAndDown }
	},


	name: "IncomeFansTrackingComparsion",


	data() {
		return {
			selectedMonthFirstPeriod: {
				month: new Date().getMonth() - 1,
				year: new Date().getFullYear()
			},
			selectedMonthSecondPeriod: {
				month: new Date().getMonth(),
				year: new Date().getFullYear()
			},
			firstPeriodLoading: false,
			firstPeriodLoaded: false,
			firstPeriod: [],
			secondPeriodLoading: false,
			secondPeriodLoaded: false,
			secondPeriod: [],
			selectedWeekFirstPeriod: null,
			selectedWeekSecondPeriod: null,
			firstPeriodItems: null,
			secondPeriodItems: null,
			resultItems: null,
			copyData: '',
		}
	},


	created() {
		this.getFirstPeriod();
		this.getSecondPeriod();
	},


	watch: {
		firstPeriodItems() {
			if (this.firstPeriodItems && this.secondPeriodItems) {
				this.calcResult()
			}
		},
		secondPeriodItems() {
			if (this.firstPeriodItems && this.secondPeriodItems) {
				this.calcResult()
			}
		},
		resultItems(value) {
			if (value) {
				this.generateCopyData()
			} else {
				this.copyData = '';
				this.$emit('table', this.copyData)
			}
		},
	},


	methods: {
		formatFirstPeriod() {
			const monthName = new Date(this.selectedMonthFirstPeriod.year, this.selectedMonthFirstPeriod.month, 1).toLocaleString('en', { month: 'long' });
			return `${monthName} ${this.selectedMonthFirstPeriod.year}`
		},
		formatSecondPeriod() {
			const monthName = new Date(this.selectedMonthSecondPeriod.year, this.selectedMonthSecondPeriod.month, 1).toLocaleString('en', { month: 'long' });
			return `${monthName} ${this.selectedMonthSecondPeriod.year}`
		},
		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},
		setMonthDate(date) {
			const year = new Date(date).getFullYear();
			const month = ("0" + (new Date(date).getMonth() + 1)).slice(-2);
			return `${month}.${year}`
		},
		async getFirstPeriod() {
			this.selectedWeekFirstPeriod = null;
			const params = {
				date_after: this.formatingDate(new Date(this.selectedMonthFirstPeriod.year, this.selectedMonthFirstPeriod.month, 1), 'yyyy-MM-dd'),
				date_before: this.formatingDate(new Date(this.selectedMonthFirstPeriod.year, this.selectedMonthFirstPeriod.month + 1, 0), 'yyyy-MM-dd'),
			}
			try {
				this.firstPeriod = [];
				this.firstPeriodLoading = true;
				this.firstPeriodLoaded = false;
				const responsive = await apiFinance.getIncomeFansTracking(params);
				responsive.forEach((item) => {
					if (item.month_data) {
						this.firstPeriod.push(item)
					}
				})
				this.firstPeriodLoading = false;
				this.firstPeriodLoaded = true;
			} catch(error) {
				console.log(error)
			}
		},
		async getSecondPeriod() {
			this.selectedWeekSecondPeriod = null;
			const params = {
				date_after: this.formatingDate(new Date(this.selectedMonthSecondPeriod.year, this.selectedMonthSecondPeriod.month, 1), 'yyyy-MM-dd'),
				date_before: this.formatingDate(new Date(this.selectedMonthSecondPeriod.year, this.selectedMonthSecondPeriod.month + 1, 0), 'yyyy-MM-dd'),
			}
			try {
				this.secondPeriod = [];
				this.secondPeriodLoading = true;
				this.secondPeriodLoaded = false;
				const responsive = await apiFinance.getIncomeFansTracking(params);
				responsive.forEach((item) => {
					if (item.month_data) {
						this.secondPeriod.push(item)
					}
				})
				this.secondPeriodLoading = false;
				this.secondPeriodLoaded = true;
			} catch(error) {
				console.log(error)
			}
		},
		swapPeriods() {
			const storeMonth = this.selectedMonthFirstPeriod;
			this.selectedMonthFirstPeriod = this.selectedMonthSecondPeriod;
			this.selectedMonthSecondPeriod = storeMonth;
			const storeWeeks = this.firstPeriod;
			this.firstPeriod = this.secondPeriod;
			this.secondPeriod = storeWeeks;
			const storeItems = this.firstPeriodItems;
			this.firstPeriodItems = this.secondPeriodItems;
			this.secondPeriodItems = storeItems;
			const storeSelectedWeek = this.selectedWeekFirstPeriod;
			this.selectedWeekFirstPeriod = this.selectedWeekSecondPeriod;
			this.selectedWeekSecondPeriod = storeSelectedWeek;
		},
		setFirstPeriodItems() { this.firstPeriodItems = this.firstPeriod.find(el => el.number === this.selectedWeekFirstPeriod) },
		setSecondPeriodItems() { this.secondPeriodItems = this.secondPeriod.find(el => el.number === this.selectedWeekSecondPeriod) },
		clearFirstPeriodItems() { this.firstPeriodItems = null; },
		clearSecondPeriodItems() { this.secondPeriodItems = null; },
		clearPeriods() {
			this.firstPeriodItems = null;
			this.secondPeriodItems = null;
			this.selectedWeekFirstPeriod = null;
			this.selectedWeekSecondPeriod = null;
			this.resultItems = null;
		},
		calcResult() {
			const firstList = this.firstPeriodItems.month_data;
			const secondList = this.secondPeriodItems.month_data;
			const newResult = [];
			const newFirstList = [];
			const newSecondList = [];
			const commonList = firstList.concat(secondList);
			const dates = Array.from(new Set(commonList.map(el => el.month_date)));
			dates.forEach((date, index) => {
				const first = firstList.find(el => el.month_date === date);
				const second = secondList.find(el => el.month_date === date);
				if (first && second) {
					newResult[index] = {
						month_date: date,
						delta: second.month_income_amount - first.month_income_amount
					}
					newFirstList[index] = first;
					newSecondList[index] = second;
				} else {
					if (first === undefined) {
						newResult[index] = {
							month_date: date,
							delta: second.month_income_amount - 0
						}
						newFirstList[index] = {
							month_date: date,
							month_income_amount: 0
						}
						newSecondList[index] = second;
					}
					if (second === undefined) {
						newResult[index] = {
							month_date: date,
							delta: 0 - first.month_income_amount
						}
						newSecondList[index] = {
							month_date: date,
							month_income_amount: 0
						}
						newFirstList[index] = second;
					}
				}
			})
			this.resultItems = newResult;
			this.firstPeriodItems.month_data = newFirstList;
			this.secondPeriodItems.month_data = newSecondList;
		},
		generateCopyData() {
			this.copyData = '';
			const rows = [];
			const firstList = this.firstPeriodItems.month_data;
			const secondList = this.secondPeriodItems.month_data;
			const commonList = firstList.concat(secondList);
			const dates = Array.from(new Set(commonList.map(el => el.month_date)));
			const header = [`${this.formatFirstPeriod()}, ${this.selectedWeekFirstPeriod} week`, '', '', `${this.formatSecondPeriod()}, ${this.selectedWeekSecondPeriod} week`, '', '', 'Result', ''];
			rows.push(header.join('\t'));
			for ( let i = 0; i < dates.length; i++ ) {
				const row = ['', 0, '', '', 0, '', '', 0];
				if (firstList[i]) { row[0] = firstList[i].month_date; row[1] = firstList[i].month_income_amount } else { row[0] = ''; row[1] = '' }
				if (secondList[i]) { row[3] = secondList[i].month_date; row[4] = secondList[i].month_income_amount } else { row[3] = ''; row[4] = '' }
				row[6] = this.resultItems[i].month_date;
				row[7] = this.resultItems[i].delta;
				const rowString = row.join('\t');
				rows.push(rowString)
			}
			const footer = ['Total:', this.firstPeriodItems.total_income_amount, '', 'Total:', this.secondPeriodItems.total_income_amount, '', 'Total delta:', this.secondPeriodItems.total_income_amount - this.firstPeriodItems.total_income_amount]
			rows.push(footer.join('\t'));
			this.copyData = rows.join('\n');
			this.$emit('table', this.copyData)
		}
	}
}
</script>

<template>
	<div>
		<v-row justify="space-between">
			<v-col cols="auto">
				<v-btn
						density="comfortable"
						variant="outlined"
						prepend-icon="mdi-swap-horizontal"
						:disabled="firstPeriodItems === null && secondPeriodItems === null"
						@click="swapPeriods"
				>Swap periods</v-btn>
			</v-col>
			<v-col cols="auto">
				<v-btn
						density="comfortable"
						variant="outlined"
						prepend-icon="mdi-delete"
						@click="clearPeriods"
				>Clear</v-btn>
			</v-col>
		</v-row>

		<div class="mt-6">
			<v-row style="min-height: 500px">
				<v-col cols="4">
					<v-sheet elevation="1" class="rounded-lg fill-height d-flex flex-column">
						<div class="flex-0-0">
							<div v-if="firstPeriodItems === null" class="text-subtitle-1 font-weight-medium text-center py-2 px-3">1 period</div>
							<div v-else class="py-2 px-3 d-flex justify-space-between">
								<div class="text-subtitle-1 font-weight-medium text-center">{{ formatFirstPeriod() }}, {{ selectedWeekFirstPeriod }} week</div>
								<v-btn variant="outlined" density="compact" prepend-icon="mdi-cogs" @click="clearFirstPeriodItems">Change</v-btn>
							</div>
							<v-divider></v-divider>
						</div>
						<div class="px-3 py-2 flex-1-1" :class="firstPeriodItems === null ? 'd-flex justify-center align-center' : ''">
							<div v-if="firstPeriodItems === null">
								<div class="text-h5 mb-4">Select the first period</div>
								<VueDatePicker
										class="density-compact clearable-false mr-4 mb-4"
										v-model="selectedMonthFirstPeriod"
										placeholder="Select month"
										auto-apply
										month-picker
										:format="formatFirstPeriod"
										:clearable="false"
										:enable-time-picker="false"
										:min-date="new Date(2024, 11)"
										@update:model-value="getFirstPeriod"
								/>
								<v-sheet width="200" v-if="firstPeriodLoaded && firstPeriod.length === 0" class="text-body-1 text-center mx-auto mb-4">Data is not ready, please select another month</v-sheet>
								<v-select
										v-else
										v-model="selectedWeekFirstPeriod"
										:items="firstPeriod"
										item-title="number"
										item-value="number"
										placeholder="Select week"
										class="mb-4"
										variant="outlined"
										density="compact"
										hide-details
										:disabled="!firstPeriodLoaded"
										:loading="!firstPeriodLoaded"
								>
									<template #selection>
										{{ selectedWeekFirstPeriod }} week
									</template>
									<template #item="{ props, item }">
										<v-list-item v-bind="props" :title="`${item.title} week`"></v-list-item>
									</template>
								</v-select>
								<div class="d-flex justify-center">
<!--									<v-btn variant="outlined">Cancel</v-btn>-->
									<v-btn :disabled="selectedWeekFirstPeriod === null" variant="flat" color="primary" @click="setFirstPeriodItems">Accept</v-btn>
								</div>
							</div>
							<div v-else>
								<template v-for="(row, rowIndex) in firstPeriodItems.month_data" :key="rowIndex">
									<v-row no-gutters :class="rowIndex % 2 ? 'bg-grey-lighten-4' : ''">
										<v-col cols="6">
											<div class="text-body-2 text-grey-darken-2 pl-2 pr-4 py-2">{{ setMonthDate(row.month_date) }}</div>
										</v-col>
										<v-col cols="6">
											<div class="text-body-2 text-grey-darken-3 text-right px-2 py-2">{{ row.month_income_amount }}$</div>
										</v-col>
									</v-row>
								</template>
								<v-row no-gutters>
									<v-col cols="6">
										<div class="text-body-2 font-weight-medium pl-2 pr-4 py-2">Total:</div>
									</v-col>
									<v-col cols="6">
										<div class="text-body-2 font-weight-medium text-right px-2 py-2">{{ firstPeriodItems.total_income_amount }}$</div>
									</v-col>
								</v-row>
							</div>
						</div>
					</v-sheet>
				</v-col>
				<v-col cols="4">
					<v-sheet elevation="1" class="rounded-lg fill-height d-flex flex-column">
						<div class=" flex-0-0">
							<div v-if="secondPeriodItems === null" class="text-subtitle-1 font-weight-medium text-center py-2 px-3">2 period</div>
							<div v-else class="py-2 px-3 d-flex justify-space-between">
								<div class="text-subtitle-1 font-weight-medium text-center">{{ formatSecondPeriod() }}, {{ selectedWeekSecondPeriod }} week</div>
								<v-btn variant="outlined" density="compact" prepend-icon="mdi-cogs" @click="clearSecondPeriodItems">Change</v-btn>
							</div>
							<v-divider></v-divider>
						</div>
						<div class="px-3 py-2 flex-1-1" :class="secondPeriodItems === null ? 'd-flex justify-center align-center' : ''">
							<div v-if="secondPeriodItems === null">
								<div class="text-h5 mb-4">Select the second period</div>
								<VueDatePicker
										class="density-compact clearable-false mr-4 mb-4"
										v-model="selectedMonthSecondPeriod"
										placeholder="Select month"
										auto-apply
										month-picker
										:format="formatSecondPeriod"
										:clearable="false"
										:enable-time-picker="false"
										:min-date="new Date(2024, 11)"
										@update:model-value="getSecondPeriod"
								/>
								<v-sheet width="200" v-if="secondPeriodLoaded && secondPeriod.length === 0" class="text-body-1 text-center mx-auto mb-4">Data is not ready, please select another month</v-sheet>
								<v-select
										v-else
										v-model="selectedWeekSecondPeriod"
										:items="secondPeriod"
										item-title="number"
										item-value="number"
										placeholder="Select week"
										class="mb-4"
										variant="outlined"
										density="compact"
										hide-details
										:disabled="!secondPeriodLoaded"
										:loading="!secondPeriodLoaded"
								>
									<template #selection>
										{{ selectedWeekSecondPeriod }} week
									</template>
									<template #item="{ props, item }">
										<v-list-item v-bind="props" :title="`${item.title} week`"></v-list-item>
									</template>
								</v-select>
								<div class="d-flex justify-center">
<!--									<v-btn variant="outlined">Cancel</v-btn>-->
									<v-btn :disabled="selectedWeekSecondPeriod === null" variant="flat" color="primary" @click="setSecondPeriodItems">Accept</v-btn>
								</div>
							</div>
							<div v-else>
								<template v-for="(row, rowIndex) in secondPeriodItems.month_data" :key="rowIndex">
									<v-row no-gutters :class="rowIndex % 2 ? 'bg-grey-lighten-4' : ''">
										<v-col cols="6">
											<div class="text-body-2 text-grey-darken-2 pl-2 pr-4 py-2">{{ setMonthDate(row.month_date) }}</div>
										</v-col>
										<v-col cols="6">
											<div class="text-body-2 text-grey-darken-3 text-right px-2 py-2">{{ row.month_income_amount }}$</div>
										</v-col>
									</v-row>
								</template>
								<v-row no-gutters>
									<v-col cols="6">
										<div class="text-body-2 font-weight-medium pl-2 pr-4 py-2">Total:</div>
									</v-col>
									<v-col cols="6">
										<div class="text-body-2 font-weight-medium text-right px-2 py-2">{{ secondPeriodItems.total_income_amount }}$</div>
									</v-col>
								</v-row>
							</div>
						</div>
					</v-sheet>
				</v-col>
				<v-col cols="4">
					<v-sheet elevation="1" class="rounded-lg fill-height d-flex flex-column">
						<div class="text-subtitle-1 font-weight-medium text-center py-2">Result</div>
						<v-divider></v-divider>
						<div class="px-3 py-2 flex-1-1" :class="firstPeriodItems === null || secondPeriodItems === null ? 'd-flex justify-center align-center' : ''">
							<div v-if="firstPeriodItems === null || secondPeriodItems === null">
								<v-sheet width="160" class="text-h5 text-center">Please select both periods</v-sheet>
							</div>
							<div v-else>
								<template v-for="(row, rowIndex) in resultItems" :key="rowIndex">
									<v-row no-gutters :class="rowIndex % 2 ? 'bg-grey-lighten-4' : ''">
										<v-col cols="6">
											<div class="text-body-2 text-grey-darken-2 pl-2 pr-4 py-2">{{ setMonthDate(row.month_date) }}</div>
										</v-col>
										<v-col cols="6">
											<div class="text-body-2 text-right px-2 py-2" :class="(row.delta < 0) ? 'text-green' : (row.delta === 0) ? 'text-grey-darken-3' : 'text-red-accent-2'"><template v-if="row.delta > 0">+</template>{{ row.delta }}<template v-if="row.delta !== '-'">$</template></div>
										</v-col>
									</v-row>
								</template>
								<v-row no-gutters>
									<v-col cols="6">
										<div class="text-body-2 font-weight-medium pl-2 pr-4 py-2">Total delta:</div>
									</v-col>
									<v-col cols="6">
										<div class="text-body-2 font-weight-medium text-right px-2 py-2" :class="(secondPeriodItems.total_income_amount - firstPeriodItems.total_income_amount < 0) ? 'text-green' : (secondPeriodItems.total_income_amount - firstPeriodItems.total_income_amount === 0) ? 'text-grey-darken-3' : 'text-red-accent-2'">
											{{ secondPeriodItems.total_income_amount - firstPeriodItems.total_income_amount }}$
										</div>
									</v-col>
								</v-row>
							</div>
						</div>
					</v-sheet>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<style scoped>

</style>