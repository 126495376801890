<script>
import {useDisplay} from "vuetify";
import DonorCard from "@/components/DonorCard.vue";
import DonorCardEditDialog from "@/components/DonorCardEditDialog.vue";
import apiAds from "@/api/ads";
import ScrollingPagination from "@/components/ScrollingPagination.vue";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	components: {
		ScrollingPagination,
		DonorCardEditDialog,
		DonorCard
	},


	name: "DonorListPage",


	data() {
		return {
			pageTitle: 'Donors list',
			donorCardDialogEditOpen: false,
			search: '',
			selectedType: null,
			selectedOptions: [],
			pageLoaded: false,
			items: [],
			itemsLoaded: false,
			itemsLoading: false,
			options: [],
			optionsLoaded: false,
			optionsLoading: false,
			types: [],
			typesLoaded: false,
			typesLoading: false,
			page: 1,
			editedItem: {},
			editedIndex: -1,
			defaultItem: {},
		}
	},


	created() {
		this.getDonors();
		this.getDonorOptions();
		this.getDonorTypes();
	},


	watch: {
		search() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.paginationReset();
				this.getDonors();
			}, 400);
		},
		selectedType() {
			if (this.itemsLoaded) {
				this.paginationReset();
				this.getDonors();
			}
		},
		selectedOptions() {
			if (this.itemsLoaded) {
				this.paginationReset();
				this.getDonors();
			}
		},
	},


	methods: {
		paginationReset() {
			this.items = [];
			this.itemsLoaded = false;
			this.pageLoaded = false;
			this.page = 1;
		},
		async getDonors() {
			try {
				const data = {
					page: this.page,
					search: this.search,
					type: this.selectedType,
					options: this.selectedOptions.join(','),
				}
				this.itemsLoaded = false;
				this.itemsLoading = true;
				const response = await apiAds.getDonorsByPage(data);
				console.log(response);
				this.items = response.results;
				this.page = response.next;
				this.itemsLoaded = true;
				this.itemsLoading = false;
				this.pageLoaded = true;
			} catch (error) {
				console.log('error', error)
			}
		},
		async getDonorOptions() {
			try {
				this.optionsLoaded = false;
				this.optionsLoading = true;
				this.options = await apiAds.getDonorOptions();
				this.optionsLoaded = true;
				this.optionsLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},
		async getDonorTypes() {
			try {
				this.typesLoaded = false;
				this.typesLoading = true;
				this.types = await apiAds.getDonorTypes();
				this.typesLoaded = true;
				this.typesLoading = false;
			} catch (error) {
				console.log('error', error)
			}
		},
		handleEditClick(info) {
			const index = this.items.findIndex(item => item.id === info.id);
			this.donorCardDialogEditOpen = true;
			this.editedItem = Object.assign({}, info)
			this.editedIndex = index;
		},
		handleCancelClick() {
			this.donorCardDialogEditOpen = false;
			this.editedItem = Object.assign({}, this.defaultItem)
			this.editedIndex = -1;
		},
		handleUpdate(response) {
			const index = this.items.findIndex(item => item.id === response.id);
			if (index !== -1) {
				this.items[index] = response
			}
		},
		async loadMore() {
			if (this.page && !this.itemsLoading) {
				try {
					const data = {
						page: this.page,
						search: this.search,
						type: this.selectedType,
						options: this.selectedOptions.join(','),
					}
					this.itemsLoaded = false;
					this.itemsLoading = true;
					const response = await apiAds.getDonorsByPage(data);
					console.log(response);
					this.items = this.items.concat(response.results);
					this.page = response.next;
					this.itemsLoaded = true;
					this.itemsLoading = false;
				} catch (error) {
					console.log('error', error)
				}
			}
		},
	}
}
</script>

<template>
	<ScrollingPagination class="fill-height overflow-y-auto" @loadMore="loadMore">
		<div class="page-container pt-4 pb-16">
			<div class="page-wrap">

				<h1 class="text-h4 mb-8">{{pageTitle}}</h1>

				<v-row justify="space-between" align="center">
					<v-col cols="auto">
						<v-row style="max-width: 100%; width: 480px">
							<v-col :cols="mdAndDown ? 12 : 6">
								<v-select
										v-model="selectedType"
										:items="types"
										item-value="id"
										item-title="name"
										variant="outlined"
										density="compact"
										label="Type"
										hide-details
										clearable
								>
								</v-select>
							</v-col>
							<v-col :cols="mdAndDown ? 12 : 6">
								<v-select
										v-model="selectedOptions"
										:items="options"
										item-value="id"
										item-title="name"
										variant="outlined"
										density="compact"
										label="Options"
										hide-details
										multiple
										clearable
								>
									<template v-slot:selection="{ index }">
										<span v-if="index === 0" class="text-grey text-caption align-self-center">Selected items: {{ selectedOptions.length }}</span>
									</template>
								</v-select>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="auto">
						<div style="max-width: 100%; width: 400px">
							<v-text-field
									v-model="search"
									variant="underlined"
									label="Search"
									append-inner-icon="mdi-magnify"
									density="compact"
									clearable
							></v-text-field>
						</div>
					</v-col>
				</v-row>

				<template v-if="pageLoaded">
					<v-row v-if="items.length > 0">
						<v-col v-for="(item, index) in items" :key="index" :cols="mdAndDown ? 12 : 4">
							<DonorCard
									:info="item"
									@update="handleUpdate"
									@edit="handleEditClick"
							/>
						</v-col>
					</v-row>
					<div v-else class="mt-10 text-body-1 text-center">
						<span>...</span><br>
						<span>There is no data matching the selected filters</span>
					</div>
				</template>

				<template v-if="itemsLoading">
					<v-row>
						<v-col v-for="(item, index) in 3" :key="index">
							<v-card>
								<v-skeleton-loader
										type="list-item-avatar, article"
								></v-skeleton-loader>
							</v-card>
						</v-col>
					</v-row>
				</template>

				<v-dialog v-model="donorCardDialogEditOpen" persistent width="600">
					<DonorCardEditDialog
							:types="types"
							:types-loaded="typesLoaded"
							:options="options"
							:options-loaded="optionsLoaded"
							:edited="editedIndex"
							:info="editedItem"
							@update="handleUpdate"
							@close="handleCancelClick"
					/>
				</v-dialog>
			</div>
		</div>
	</ScrollingPagination>
</template>

<style scoped>

</style>