import axios from "@/services/axios-instance";

export default {
  async getPeriods() {
    const response = await axios.get('/v1/models-plan/marketing-budget-periods/');
    return response.data
  },

  async getPeriod(id, type, date_filter) {
    const response = await axios.get(`/v1/models-plan/marketing-budget-periods/${id}/`, {params: {promo_type: type, date_filter: date_filter}});
    return response.data
  },

  async getPeriodCopy(id, type, date_filter){
    const response = await axios.get(`/v1/models-plan/marketing-budget-periods/${id}/copy-table/`, {params: {promo_type: type, date_filter: date_filter}});
    return response.data
  },

  async addPeriod(data) {
    const response = await axios.post('/v1/models-plan/marketing-budget-periods/', data);
    return response.data
  },

  async editPeriod(id, data){
    const response = await axios.patch(`/v1/models-plan/marketing-budget-periods/${id}/`, data);
    return response.data
  },

  async addPlanModel(data) {
    const response = await axios.post('/v1/models-plan/marketing-budget-expected-results/', data);
    return response.data
  },

  async editPlanModel(id, data) {
    const response = await axios.patch(`/v1/models-plan/marketing-budget-expected-results/${id}/`, data);
    return response.data
  },

  async deletePlanModel(id) {
    const response = await axios.delete(`/v1/models-plan/marketing-budget-expected-results/${id}/`);
    return response.data
  },
}