<script>
import apiFinance from "@/api/finance";

export default {
	name: "WidgetFuturePurchases",


	props: {
		currentMonth: {}
	},


	data() {
		return {
			itemsLoading: false,
			itemsLoaded: false,
			items: [],
			total: null,
			copyLoading: false,
			copyLoaded: false,
			copy: null,
		}
	},


	created() {
		this.getFuturePurchase();
		this.getFuturePurchaseCopy()
	},


	methods: {
		async getFuturePurchase() {
			try {
				this.itemsLoading = true;
				this.itemsLoaded = false;
				const response = await apiFinance.getFuturePurchase();
				this.items = response.purchases;
				this.total = response.total;
				this.itemsLoading = false;
				this.itemsLoaded = true
			} catch (error) {
				console.log(error)
			}
		},
		async getFuturePurchaseCopy() {
			try {
				this.copyLoading = true;
				this.copyLoaded = false;
				this.copy = await apiFinance.getFuturePurchaseCopy();
				this.copyLoading = false;
				this.copyLoaded = true
			} catch (error) {
				console.log(error)
			}
		},
		copyTable() {
			window.navigator.clipboard.writeText(this.copy.copy_data)
		}
	}
}
</script>

<template>
	<v-sheet v-if="itemsLoaded && copyLoaded" class="rounded-lg px-5 py-4 elevation-1" height="436">
		<div class="fill-height d-flex flex-column">
			<div class="flex-0-0">
				<v-row no-gutters justify="space-between" align="center" class="mr-n2">
					<v-col cols="auto">
						<div class="text-h6">Future purchases</div>
					</v-col>
					<v-col cols="auto">
						<v-btn variant="flat" size="small" icon="mdi-content-copy" @click="copyTable"></v-btn>
					</v-col>
				</v-row>
			</div>
			<div class="flex-1-1 overflow-y-auto mx-n2 mt-6 px-2 pb-1">
				<div class="mx-n2 mb-n1">
					<v-row
							class="px-2 py-1 rounded"
							:class="{'bg-light-blue-accent-3': currentMonth === item.month}"
							no-gutters v-for="(item, index) in items"
							:key="index"
					>
						<v-col class="text-body-2 text-left" :class="currentMonth === item.month ? 'text-white': 'text-grey-darken-2'">{{item.month}}</v-col>
						<v-col class="text-body-2 text-right" :class="currentMonth === item.month ? 'text-white': 'text-grey-darken-2'">{{item.amount.toLocaleString('ru')}}$</v-col>
					</v-row>
				</div>
			</div>
			<v-row class="pt-3 flex-0-0">
				<v-col class="text-body-2 text-left font-weight-bold">Total:</v-col>
				<v-col class="text-body-2 text-right">{{ total.toLocaleString('ru') }}$</v-col>
			</v-row>
		</div>
	</v-sheet>
	<v-card v-else class="pa-4 fill-height rounded-lg d-flex align-center justify-center" height="436">
		<v-progress-circular indeterminate color="primary"></v-progress-circular>
	</v-card>
</template>

<style scoped>

</style>