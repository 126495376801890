import axios from "@/services/axios-instance";

export default {
  async getDepartments() {
    const response = await axios.get('/v1/finance/cashflow/departments/');
    return response.data
  },

  async getCreators() {
    const response = await axios.get('/v1/finance/cashflow/creators/');
    return response.data
  },

  async getExpenseItems() {
    const response = await axios.get('/v1/finance/cashflow/expense-items/');
    return response.data
  },

  async getExpenseSubItems() {
    const response = await axios.get('/v1/finance/cashflow/expense-sub-items/');
    return response.data
  },

  async getCashflowRequests(data) {
    const response = await axios.get('/v1/finance/cashflow/requests/', { params: data });
    return response.data
  },

  async addCashflowRequest(data) {
    const response = await axios.post('/v1/finance/cashflow/requests/', data);
    return response.data
  },

  async patchCashflowRequest(id, data) {
    const response = await axios.patch(`/v1/finance/cashflow/requests/${id}/`, data);
    return response.data
  },

  // cashflow income
  async getIncomeExpenseItems() {
    const response = await axios.get('/v1/finance/cashflow/income-items/');
    return response.data
  },

  async getCashflowIncomeRequests(data) {
    const response = await axios.get('/v1/finance/cashflow/income-requests/', { params: data });
    return response.data
  },

  async addCashflowIncomeRequest(data) {
    const response = await axios.post('/v1/finance/cashflow/income-requests/', data);
    return response.data
  },

  async patchCashflowIncomeRequest(id, data) {
    const response = await axios.patch(`/v1/finance/cashflow/income-requests/${id}/`, data);
    return response.data
  },
}