<template>

	<div class="fill-height d-flex flex-column">

		<div class="flex-0-0">
			<h1 class="text-h4 mb-8">{{ title }}</h1>
			<v-row no-gutters justify="space-between" class="mb-2">
				<v-col class="v-col-auto">
					<v-row>
						<v-col>
							<div style="max-width: 100%; width: 220px">
								<VueDatePicker
										class="density-compact clearable-false"
										v-model="date"
										range
										auto-apply
										:format="format"
										:clearable="false"
								/>
							</div>
						</v-col>
						<v-col>
							<div style="max-width: 100%; width: 180px">
								<v-autocomplete
										v-model="model"
										:loading="!commonListModelsLoaded"
										:disabled="!commonListModelsLoaded"
										density="compact"
										placeholder="Model"
										variant="outlined"
										item-title="nickname"
										item-value="id"
										:items="updatedModelsList"
										hide-details
								>

								</v-autocomplete>
<!--								<SelectModel-->
<!--										v-model="model"-->
<!--										:items="commonListModels"-->
<!--										:loading="!commonListModelsLoaded"-->
<!--										:disabled="!commonListModelsLoaded"-->
<!--										density="compact"-->
<!--								/>-->
							</div>
						</v-col>
						<v-col>
							<div style="max-width: 100%; width: 260px">
								<v-select
										v-model="statusSelected"
										variant="outlined"
										density="compact"
										:items="statusList"
										label="Status"
										item-title="title"
										item-value="value"
										multiple
										hide-details
								>
									<template v-slot:prepend-item>
										<v-list-item
												title="Select All"
												@click="toggleSelectAllStatus"
										>
											<template v-slot:prepend>
												<v-checkbox-btn
														:color="selectSomeStatus ? 'indigo-darken-4' : undefined"
														:indeterminate="selectSomeStatus && !selectAllStatus"
														:model-value="selectSomeStatus"
												></v-checkbox-btn>
											</template>
										</v-list-item>
										<v-divider class="mt-2"></v-divider>
									</template>
									<template v-slot:selection="{ item, index }">
										<v-avatar v-if="index < 6" class="mr-1" :color="item.raw.color" size="x-small">
											<v-icon size="x-small">{{ item.raw.icon }}</v-icon>
										</v-avatar>
										<span
												v-if="index === 6"
												class="text-grey text-caption align-self-center">+{{ statusSelected.length - 6 }}</span>
									</template>
								</v-select>
							</div>
						</v-col>
					</v-row>
				</v-col>
				<v-col class="v-col-auto">
					<div style="max-width: 100%; width: 340px">
						<v-text-field
								v-model="search"
								variant="underlined"
								label="Search"
								append-inner-icon="mdi-magnify"
								density="compact"
								hide-details
						></v-text-field>
					</div>
				</v-col>
			</v-row>
		</div>

		<div class="flex-1-1 overflow-y-auto">
			<ScrollingPagination class="overflow-x-auto fill-height px-1" @loadMore="loadMore">
				<div class="d-flex w-fit bg-background position-sticky" style="top: 0; z-index: 1">
					<div
							v-for="(item, index) in heads"
							:key="index"
							class="pa-2 pb-3 text-body-1 font-weight-medium"
							style="width: unset"
							:style="{ width: sizes[item] + 'px' }"
							v-html="headName(item)"
					></div>
				</div>

				<template v-if="items.length > 0">
					<template v-for="(item, index) in items" :key="index">
						<v-hover>
							<template v-slot:default="{ isHovering, props }">
								<div
										v-if="item.id"
										v-bind="props"
										class="d-flex rounded-lg mb-2 w-fit transition elevation-1"
										:class="[setItemBgColor(index, isHovering, item.status?.name), {'cursor-pointer': userInfo.role === 'hom' || userInfo.role === 'marketer' || userInfo.role === 'hobd' || userInfo.role === 'business_dev'}]"
										@click="(userInfo.role === 'hom' || userInfo.role === 'marketer' || userInfo.role === 'hobd' || userInfo.role === 'business_dev') ? editItem(item) : {}"
										style="opacity: 1; border: 1px solid transparent;"
								>
									<div :class="tableCellClass" :style="{ width: sizes.friends_status + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%">
											<span v-if="item.friends_status === true">✅</span>
											<span v-else-if="item.friends_status === false">❌</span>
											<span v-else></span>
										</div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.date + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.date)"><span>{{ item.date }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.trial_link + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.trial_link)"><span>{{ item.trial_link }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.buy_date + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.buy_date)"><span>{{ item.buy_date }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.cost + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.cost)"><span>{{ item.cost }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.revenue + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.revenue)"><span>{{ item.revenue }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.cost_per_fan + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.cost_per_fan)"><span>{{ item.cost_per_fan }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.profit + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.profit)"><span>{{ item.profit }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.romi + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.romi)"><span>{{ item.romi }}<template v-if="item.romi">%</template></span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.apru + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.apru)"><span>{{ item.apru }}<template v-if="item.apru">$</template></span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.payment + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.payment)"><span>{{ item.payment }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.claims_count + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.claims_count)"><span>{{ item.claims_count }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.spenders + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.spenders)"><span>{{ item.spenders }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.only_fans_model + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.only_fans_model?.nickname)"><span>{{ item.only_fans_model?.nickname }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.comment + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.comment)"><span>{{ item.comment }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.platform_type + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.platform_type?.name)"><span>{{ item.platform_type?.name }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.donor + 'px' }">
										<div class="d-flex align-center" :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.donor?.name)"><span>{{ item.donor?.name }}</span><v-icon v-if="item.donor?.is_blacklist" color="primary" class="ml-2">mdi-cancel</v-icon><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.of_link + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.of_link)"><span>{{ item.of_link }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.promo + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.promo?.name)"><span>{{ item.promo?.name }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.fans_count + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.fans_count)"><span>{{ item.fans_count }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.cwvt + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.cwvt)"><span>{{ item.cwvt }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.payment_method + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.payment_method?.name)"><span>{{ item.payment_method?.name }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.of_link_date + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.of_link_date)"><span>{{ item.of_link_date }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.marketer + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.marketer?.full_name)"><span>{{ item.marketer?.full_name }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.fans_delta + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.fans_delta)"><span>{{ item.fans_delta }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.cost_result + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.cost_result)"><span>{{ item.cost_result }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.refund_date + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.refund_date)"><span>{{ item.refund_date }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.refund_cost + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.refund_cost)"><span>{{ item.refund_cost }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.new_fans_count + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.new_fans_count)"><span>{{ item.new_fans_count }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.refund_payment + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.refund_payment)"><span>{{ item.refund_payment }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
									<div :class="tableCellClass" :style="{ width: sizes.ads_number + 'px' }">
										<div :class="tableCellInnerClass" style="max-width: 100%" @click.stop="copyField(item.ads_number)"><span>{{ item.ads_number }}</span><v-tooltip activator="parent" location="top">Copy field</v-tooltip></div>
									</div>
								</div>
								<div
										v-else
										v-bind="props"
										class="d-flex rounded-lg mb-2 w-fit transition"
										:class="[setItemBgColor(index, isHovering, 'draft'), {'cursor-pointer': ((userInfo.role === 'hom' || userInfo.role === 'marketer' || userInfo.role === 'hobd' || userInfo.role === 'business_dev') && item.is_blocked === false)}]"
										style="opacity: .4; border: 1px solid #ccc;"
										@click="((userInfo.role === 'hom' || userInfo.role === 'marketer' || userInfo.role === 'hobd' || userInfo.role === 'business_dev') && item.is_blocked === false) ? editItem(item) : {}"
								>
									<div :class="tableCellClass" :style="{ width: sizes.friends_status + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.date + 'px' }">{{ item.date }}</div>
									<div :class="tableCellClass" :style="{ width: sizes.trial_link + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.buy_date + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.cost + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.revenue + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.cost_per_fan + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.profit + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.romi + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.apru + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.payment + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.claims_count + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.spenders + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.only_fans_model + 'px' }">{{ modelObject.nickname }}</div>
									<div :class="tableCellClass" :style="{ width: sizes.comment + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.platform_type + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.donor + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.of_link + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.promo + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.fans_count + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.cwvt + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.payment_method + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.of_link_date + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.marketer + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.fans_delta + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.cost_result + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.refund_date + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.refund_cost + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.new_fans_count + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.refund_payment + 'px' }"></div>
									<div :class="tableCellClass" :style="{ width: sizes.ads_number + 'px' }"></div>
								</div>
							</template>
						</v-hover>
					</template>
				</template>
				<template v-if="items.length === 0 && itemsLoaded">
					<div class="mt-10 text-body-1 text-center">
						<span>...</span><br>
						<span>Нет данных, соответствующих выбранным фильтрам</span>
					</div>
				</template>

				<v-skeleton-loader v-if="itemsLoading" type="table-tbody" class="bg-transparent mb-6"></v-skeleton-loader>

			</ScrollingPagination>
		</div>

		<v-dialog v-model="dialog" fullscreen persistent transition="slide-x-transition" max-width="1100px" class="right-position">
			<PromoEditDialog
					@close="close"
					@edit="editAd"
					@add="addAd"
					@delete="deleteAd"
					@copy="addCopy"
					@swap="applySwap"
					@link-to-promo="switchPromo"
					:info="editedItem"
			/>
		</v-dialog>

	</div>

</template>

<script>
import {mapState} from "vuex";
// import SelectModel from '@/components/SelectModel.vue';
import PromoEditDialog from '@/components/PromoEditDialog.vue';
import apiAds from "@/api/ads";
import ScrollingPagination from "@/components/ScrollingPagination.vue";
import {useDisplay} from "vuetify";
import {toast} from "vuetify-sonner";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();
		return { lgAndUp, mdAndDown }
	},


	name: 'MarketingView',


	props: {
		title: {
			type: String
		}
	},


	components: {
		ScrollingPagination,
		// SelectModel,
		PromoEditDialog,
	},


	data() {
		return {
			dialog: false,
			editedItem: {},
			model: '',
			statusSelected: [],
			statusList: [
				{ title: 'Draft', value: 'draft', icon: 'mdi-pencil', color: 'white' },
				{ title: 'Progress', value: 'progress', icon: 'mdi-play', color: 'amber-lighten-4' },
				{ title: 'Done', value: 'done', icon: 'mdi-check', color: 'green-lighten-4' },
				{ title: 'Other type', value: 'other_type', icon: 'mdi-advertisements', color: 'indigo-lighten-4' },
				{ title: 'Swap', value: 'swap', icon: 'mdi-swap-horizontal', color: 'purple-lighten-4' },
				{ title: 'Problem', value: 'problem', icon: 'mdi-exclamation-thick', color: 'red-lighten-4' },
			],
			modelObject: '',
			date: this.setDefaultDateRange(),
			format: 'dd.MM.yyyy',
			tableCellClass: 'px-2 py-3 text-body-2 d-flex',
			tableCellInnerClass: 'text-no-wrap overflow-hidden text-overflow-ellipsis',
			search: '',
			page: 1,
			ordering: 'date',
			updatedModelsList: [],
			sizes: {
				friends_status: '100',
				date: '100',
				trial_link: '100',
				buy_date: '100',
				cost: '90',
				payment: '150',
				only_fans_model: '130',
				payment_method: '120',
				platform_type: '150',
				donor: '190',
				promo: '150',
				fans_count: '150',
				cwvt: '130',
				comment: '300',
				of_link: '100',
				of_link_date: '100',
				marketer: '150',
				revenue: '110',
				cost_per_fan: '90',
				profit: '110',
				romi: '110',
				apru: '110',
				claims_count: '100',
				spenders: '100',
				fans_delta: '150',
				cost_result: '150',
				refund_date: '100',
				refund_cost: '150',
				new_fans_count: '150',
				refund_payment: '150',
				ads_number: '100',
			},
			heads: [
				'friends_status',
				'date',
				'trial_link',
				'buy_date',
				'cost',
				'revenue',
				'cost_per_fan',
				'profit',
				'romi',
				'apru',
				'payment',
				'claims_count',
				'spenders',
				'only_fans_model',
				'comment',
				'platform_type',
				'donor',
				'of_link',
				'promo',
				'fans_count',
				'cwvt',
				'payment_method',
				'of_link_date',
				'marketer',
				'fans_delta',
				'cost_result',
				'refund_date',
				'refund_cost',
				'new_fans_count',
				'refund_payment',
				'ads_number',
			],
			items: [],
			itemsLoaded: false,
			itemsLoading: false,
			emptyItem: {
				buy_date: null,
				claims_count: null,
				comment: null,
				cost: null,
				cwvt: null,
				donor: null,
				fans_count: null,
				fans_delta: null,
				cost_result: null,
				id: null,
				marketer: null,
				of_link: null,
				of_link_date: null,
				only_fans_model: null,
				payment: null,
				payment_method: null,
				platform_type: null,
				cost_per_fan: null,
				profit: null,
				promo: null,
				revenue: null,
				romi: null,
				apru: null,
				status: null,
				trial_link: null,
				refund_date: null,
				refund_cost: null,
				new_fans_count: null,
				refund_payment: null,
				ads_number: null,
				spenders: null,
			},
		}
	},


	computed: {
		...mapState({
			userInfo: state => state.user.info,
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		}),
		selectAllStatus () {
			return this.statusSelected.length === this.statusList.length
		},
		selectSomeStatus () {
			return this.statusSelected.length > 0
		},
	},


	created() {
		if (localStorage.ads_date_start && localStorage.ads_date_end) {
			this.date[0] = new Date(localStorage.ads_date_start);
			this.date[1] = new Date(localStorage.ads_date_end);
		}
		if (localStorage.ads_model) {
			this.model = localStorage.ads_model;
		}
		if ( localStorage.status ) {
			this.statusSelected = localStorage.status.split(',')
		}
		if (this.commonListModelsLoaded) {
			this.updatedModelsList = Object.assign([], this.commonListModels);
			this.updatedModelsList.unshift({ id: 'all', nickname: 'Select all' })
		}
	},


	watch: {
		date() {
			this.paginationReset();
			this.getAds();
			localStorage.ads_date_start = this.date[0];
			localStorage.ads_date_end = this.date[1];
		},
		model() {
			this.paginationReset();
			if (this.commonListModelsLoaded) {
				this.getAds();
				if (this.model === null) {
					localStorage.ads_model = '';
				} else {
					localStorage.ads_model = this.model;
				}
				this.modelObject = this.getModelObjectById(this.model)
			}
		},
		commonListModelsLoaded(value) {
			if (value) {
				this.updatedModelsList = Object.assign([], this.commonListModels);
				this.updatedModelsList.unshift({ id: 'all', nickname: 'Select all' })
				if (localStorage.ads_model === undefined) {
					this.model = this.commonListModels[0].id;
				} else {
					this.model = localStorage.ads_model;
				}
				this.modelObject = this.getModelObjectById(this.model)
				this.paginationReset();
				this.getAds()
			}
		},
		statusSelected() {
			this.paginationReset();
			this.getAds();
			localStorage.status = this.statusSelected
		},
		search() {
			clearTimeout(this.timer);
			this.timer = setTimeout(() => {
				this.paginationReset();
				this.getAds()
			}, 400);
		},
	},


	methods: {
		toggleSelectAllStatus () {
			if (this.selectAllStatus) {
				this.statusSelected = []
			} else {
				this.statusSelected = Array.from(new Set(this.statusList.map(el => el.value)));
			}
		},

		paginationReset() {
			this.items= [];
			this.itemsLoaded = false;
			this.page = 1;
		},

		setDefaultDateRange() {
			const now = new Date();
			const start = new Date(now.getFullYear(), now.getMonth(), -14);
			const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
			return [start, end]
		},

		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},

		getModels() {
			this.modelsList = this.$store.getters['user/getModels'];
		},

		async getAds() {
			if (this.commonListModelsLoaded) {
				const params = {
					date_after: this.formatingDate(this.date[0], 'yyyy-MM-dd'),
					date_before: this.formatingDate(this.date[1], 'yyyy-MM-dd'),
					search: this.search,
					page: this.page,
					status: this.statusSelected.join(','),
				}
				if (this.model !== 'all') {
					params.only_fans_model = this.model
				}
				try {
					this.itemsLoaded = false;
					this.itemsLoading = true;
					const response = await apiAds.getAds(params);
					this.items = this.items.concat(response.results);
					this.page = response.next;
					this.itemsLoaded = true;
					this.itemsLoading = false;
				} catch (error) {
					console.log('error', error)
				}
			}
		},

		loadMore() {
			if (this.page && !this.itemsLoading) {
				this.getAds()
			}
		},

		getModelObjectById(id) {
			return this.$store.getters['user/getModelObjectById'](id);
		},

		headName(value) {
			if (value === 'friends_status') return 'Friends status';
			if (value === 'date') return 'Date';
			if (value === 'trial_link') return 'Link';
			if (value === 'buy_date') return 'Buy date';
			if (value === 'cost') return 'Cost';
			if (value === 'payment') return 'Payment';
			if (value === 'only_fans_model') return 'Our model';
			if (value === 'payment_method') return 'Payment method';
			if (value === 'platform_type') return 'Platform type';
			if (value === 'donor') return 'Donor';
			if (value === 'promo') return 'Promo';
			if (value === 'fans_count') return 'Fans count';
			if (value === 'cwvt') return 'Cost without VAT and fee';
			if (value === 'comment') return 'Comment';
			if (value === 'of_link') return 'Of link';
			if (value === 'of_link_date') return 'Of link date';
			if (value === 'marketer') return 'Marketer';
			if (value === 'status') return 'Status';
			if (value === 'revenue') return 'Revenue';
			if (value === 'cost_per_fan') return 'Cost per fan';
			if (value === 'profit') return 'Profit';
			if (value === 'romi') return 'ROMI';
			if (value === 'apru') return 'APRU';
			if (value === 'claims_count') return 'Claims Count';
			if (value === 'fans_delta') return 'Fans delta';
			if (value === 'cost_result') return 'Cost result';
			if (value === 'refund_date') return 'Refund date';
			if (value === 'refund_cost') return 'Refund cost';
			if (value === 'new_fans_count') return 'New fans count';
			if (value === 'refund_payment') return 'Refund payment';
			if (value === 'ads_number') return 'ID';
			if (value === 'spenders') return 'Spenders';
		},

		setItemBgColor(index, hover, status) {
			if (hover) {
				return 'bg-cyan-lighten-5'
			} else if (status === 'progress') {
				return 'bg-amber-lighten-4'
			} else if (status === 'done') {
				return 'bg-green-lighten-4'
			} else if (status === 'other_type') {
				return 'bg-indigo-lighten-4'
			} else if (status === 'swap') {
				return 'bg-purple-lighten-4'
			} else if (status === 'problem') {
				return 'bg-red-lighten-4'
			} else if (index % 2) {
				return 'bg-grey-lighten-4'
			} else {
				return 'bg-white'
			}
		},

		createNewEmptyPromo(old_date, is_blocked) {
			return {
				ads_number: null,
				buy_date: null,
				claims_count: null,
				comment: null,
				cost: null,
				cost_result: null,
				cwvt: null,
				date: old_date,
				donor: null,
				fans_count: null,
				fans_delta: null,
				id: null,
				is_blocked: is_blocked,
				marketer: null,
				new_fans_count: null,
				of_link: null,
				of_link_date: null,
				only_fans_model: null,
				payment: null,
				payment_method: null,
				platform_type: null,
				profit: null,
				promo: null,
				refund_cost: null,
				refund_date: null,
				refund_payment: null,
				revenue: null,
				romi: null,
				spenders: 0,
				spenders_high: 0,
				spenders_low: 0,
				status: null,
				trial_link: null
			}
		},

		editItem(item) {
			this.editedItem = Object.assign({}, item);
			this.editedItem.only_fans_model_object = this.modelObject;
			if (!this.editedItem.status) {
				this.editedItem.status = 'draft'
			}
			if (!this.editedItem.only_fans_model) {
				this.editedItem.only_fans_model = { id: this.modelObject.id, nickname: this.modelObject.nickname }
			}
			this.dialog = true
		},

		switchPromo(response) {
			this.$emit('close');
			this.$nextTick(() => {
				this.editItem(response)
			})
		},

		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, {});
			})
		},

		addAd({response}) {
			const index = this.items.findIndex(item => item.date === response.date);
			this.items[index] = response
		},

		editAd({response, old_date, old_model, is_blocked}) {
			//если изменилась дата
			if (old_date && (old_date !== response.date)) {
				const matchModel = this.model === response.only_fans_model.id || this.model === 'all';
				const matchDate = new Date(this.date[0]) <= new Date(response.date) && new Date(this.date[1]) >= new Date(response.date);
				const matchFilters = matchModel && matchDate;
				console.log(matchFilters);
				if (matchFilters) {
					if (this.model === 'all' || this.model === null || this.statusSelected.length > 0) {
						const oldIndex = this.items.findIndex(item => item.ads_number === response.ads_number);
						this.items[oldIndex] = response;
						this.items.sort((a, b) => a.date > b.date ? 1 : -1);
					} else {
						const oldIndex = this.items.findIndex(item => item.date === old_date);
						const newIndex = this.items.findIndex(item => item.date === response.date);
						this.items[oldIndex] = this.createNewEmptyPromo(old_date, is_blocked);
						this.items[newIndex] = response;
					}
				} else {
					if (this.model === 'all' || this.model === null || this.statusSelected.length > 0) {
						const oldIndex = this.items.findIndex(item => item.ads_number === response.ads_number);
						this.items.splice(oldIndex, 1);
					} else {
						const oldIndex = this.items.findIndex(item => item.date === old_date);
						this.items[oldIndex] = this.createNewEmptyPromo(old_date, is_blocked);
					}
				}
			//если изменилась модель
			} else if (old_model && (old_model !== response.only_fans_model.id)) {
				const matchModel = this.model === response.only_fans_model.id || this.model === 'all';
				if (matchModel) {
					const oldIndex = this.items.findIndex(item => item.ads_number === response.ads_number);
					this.items[oldIndex] = response;
				} else {
					const oldIndex = this.items.findIndex(item => item.date === old_date);
					this.items[oldIndex] = this.createNewEmptyPromo(old_date, is_blocked);
				}
			//изменение остальных полей
			} else {
				const index = this.items.findIndex(item => item.ads_number === response.ads_number);
				this.items[index] = response
			}
		},

		addCopy(response) {
			const index = this.items.findIndex(item => item.date === response.date);
			if (index !== -1) {
				this.items[index] = response
			}
		},

		applySwap(response) {
			const index = this.items.findIndex(item => item.ads_number === response.ads_number);
			this.items[index] = response;
			this.editItem(response)
		},

		deleteAd({id, old_date}) {
			const index = this.items.findIndex(item => item.id === id);
			this.items[index] = this.emptyItem
			this.items[index].date = old_date
		},

		copyField(value) {
			window.navigator.clipboard.writeText(value);
			toast('Field copied:', {
				duration: 5000,
				description: 'Field contents copied to clipboard',
				cardProps: {
					color: 'success',
				},
			});
		}
	}
}
</script>

<style scoped>

</style>