<script>
import {useDisplay} from "vuetify";
import apiFinance from "@/api/finance";
import apiFinanceCashflow from "@/api/finance-cashflow";
import {mapState} from "vuex";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();
		return { lgAndUp, mdAndDown }
	},


	name: "PaymentEditDialog",


	props: {
		info: {},
		creators: {},
		creatorsLoaded: {},
	},


	data() {
		return {
			formLoading: false,
			format: 'dd.MM.yyyy',
			fields: {},
			errors: {},
			expenseItems: [],
			expenseItemsLoading: false,
			expenseItemsLoaded: false,
			expenseSubItems: [],
			expenseSubItemsLoading: false,
			expenseSubItemsLoaded: false,
			currencies: [],
			currenciesLoading: false,
			currenciesLoaded: false,
			paymentSystems: [],
			paymentSystemsLoading: false,
			paymentSystemsLoaded: false,
			departments: [],
			departmentsLoading: false,
			departmentsLoaded: false,
			paymentsAmountError: '',
		}
	},


	created() {
		this.setFields();
		this.getExpenseItems();
		this.getExpenseSubItems();
		this.getCurrencies();
		this.getPaymentSystems();
		this.getDepartments();
	},


	computed: {
		...mapState({
			userInfo: state => state.user.info,
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		})
	},


	watch: {

	},


	methods: {

		formatingDate(date, format) {
			console.log(date)
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},

		setFields() {
			this.fields.status = this.info.status;
			this.fields.date = new Date();
			this.fields.payment_date = new Date();
			this.fields.enrollment_date = new Date();
			this.fields.department = this.info.department
			this.fields.cashflow_expense_item = this.info.cashflow_expense_item;
			this.fields.cashflow_expense_sub_item = this.info.cashflow_expense_sub_item;
			this.fields.model_nickname = this.info.model_nickname;
			this.fields.payment_system = this.info.payment_system;
			this.fields.amount = null;
			this.fields.currency = this.info.currency;
			this.fields.payment_details	= this.info.payment_details;
			this.fields.payment_description	= this.info.payment_description;
			this.fields.created_by	= this.info.created_by;
			this.fields.financier	= this.info.financier;
		},

		async getExpenseItems() {
			this.expenseItemsLoading = true;
			this.expenseItemsLoaded = false;
			this.expenseItems = await apiFinanceCashflow.getExpenseItems();
			this.expenseItemsLoading = false;
			this.expenseItemsLoaded = true;
		},

		async getExpenseSubItems() {
			this.expenseSubItemsLoading = true;
			this.expenseSubItemsLoaded = false;
			this.expenseSubItems = await apiFinanceCashflow.getExpenseSubItems();
			this.expenseSubItemsLoading = false;
			this.expenseSubItemsLoaded = true;
		},

		async getCurrencies() {
			this.currenciesLoading = true;
			this.currenciesLoaded = false;
			this.currencies = await apiFinance.getCurrencies()
			this.currenciesLoading = false;
			this.currenciesLoaded = true;
		},

		async getPaymentSystems() {
			this.paymentSystemsLoading = true;
			this.paymentSystemsLoaded = false;
			this.paymentSystems = await apiFinance.getPaymentSystems()
			this.paymentSystemsLoading = false;
			this.paymentSystemsLoaded = true;
		},

		async getDepartments() {
			this.departmentsLoading = true;
			this.departmentsLoaded = false;
			this.departments = await apiFinanceCashflow.getDepartments()
			this.departmentsLoading = false;
			this.departmentsLoaded = true;
		},

		async handleRequestUpdate() {
			this.formLoading = true;
			try {
				const data = {
					created_by: this.fields.created_by,
					financier: this.fields.financier,
					payment_date: this.formatingDate(this.fields.payment_date, 'yyyy-MM-dd'),
					enrollment_date: this.formatingDate(this.fields.enrollment_date, 'yyyy-MM-dd'),
					amount: this.fields.amount,
					model_nickname: this.fields.model_nickname,
					payment_description: this.fields.payment_description,
					payment_details: this.fields.payment_details,
					status: this.fields.status,
				};
				if (this.fields.date) { data.date = this.formatingDate(this.fields.date, 'yyyy-MM-dd') }
				if (this.fields.cashflow_expense_item) { data.cashflow_expense_item = this.fields.cashflow_expense_item }
				if (this.fields.cashflow_expense_sub_item) { data.cashflow_expense_sub_item = this.fields.cashflow_expense_sub_item }
				if (this.fields.currency) { data.currency = this.fields.currency }
				if (this.fields.payment_system) { data.payment_system = this.fields.payment_system }
				if (this.fields.department) { data.department = this.fields.department }
				const response = await apiFinanceCashflow.patchCashflowRequest(this.info.id, data);
				this.$emit('update', response);
				this.$emit('close');
			} catch (error) {
				console.log(error)
				const errors = Object.keys(error.response.data);
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
				});
			} finally {
				this.formLoading = false;
			}
		},

		async handleRequestCreate() {
			this.errors = {};
			this.formLoading = true;
			try {
				const data = {
					created_by: this.fields.created_by,
					financier: this.fields.financier,
					payment_date: this.formatingDate(this.fields.payment_date, 'yyyy-MM-dd'),
					enrollment_date: this.formatingDate(this.fields.enrollment_date, 'yyyy-MM-dd'),
					amount: this.fields.amount,
					model_nickname: this.fields.model_nickname,
					payment_description: this.fields.payment_description,
					payment_details: this.fields.payment_details,
					status: this.fields.status,
				};
				if (this.fields.date) { data.date = this.formatingDate(this.fields.date, 'yyyy-MM-dd') }
				if (this.fields.cashflow_expense_item) { data.cashflow_expense_item = this.fields.cashflow_expense_item }
				if (this.fields.cashflow_expense_sub_item) { data.cashflow_expense_sub_item = this.fields.cashflow_expense_sub_item }
				if (this.fields.currency) { data.currency = this.fields.currency }
				if (this.fields.payment_system) { data.payment_system = this.fields.payment_system }
				if (this.fields.department) { data.department = this.fields.department }
				const response = await apiFinanceCashflow.addCashflowRequest(data);
				this.$emit('create', response);
				this.$emit('close');
			} catch (error) {
				console.log(error)
				const errors = Object.keys(error.response.data);
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
				});
			} finally {
				this.formLoading = false;
			}
		}
	}
}
</script>

<template>
	<v-card class="pa-6" :disabled="formLoading">
		<v-form class="fill-height d-flex flex-column" @submit.prevent>

			<div class="flex-0-0">
				<div class="mb-4 text-h5">Payment id: {{ info.id }}</div>
				<v-divider></v-divider>
			</div>

			<div class="flex-1-1 overflow-y-auto py-4 px-3 mx-n3">
				<v-sheet max-width="500" class="mx-auto">
					<v-stepper editable class="elevation-0" v-model="fields.status">
						<v-stepper-header>
							<v-stepper-item
									value="reject"
									color="amber-lighten-4"
									title="Canceled"
									edit-icon="mdi-exclamation-thick"
							></v-stepper-item>
							<v-divider></v-divider>
							<v-stepper-item
									value="accept"
									color="green-lighten-4"
									title="Provided"
									edit-icon="mdi-check"
							></v-stepper-item>
						</v-stepper-header>
					</v-stepper>
				</v-sheet>

				<div class="my-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Link info</div>
					<v-row class="flex-wrap">
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2">Request date</div>
							<VueDatePicker
									class="clearable-false"
									v-model="fields.date"
									auto-apply
									:format="format"
									:clearable="false"
									:class="errors.date ? 'mb-1' : 'mb-6'"
									:state="(errors.date) ? !errors.date : null"
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2">Payment date</div>
							<VueDatePicker
									class="clearable-false"
									v-model="fields.payment_date"
									auto-apply
									:format="format"
									:clearable="false"
									:class="errors.payment_date ? 'mb-1' : 'mb-6'"
									:state="(errors.payment_date) ? !errors.payment_date : null"
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2">Enrollment date</div>
							<VueDatePicker
									class="clearable-false"
									v-model="fields.enrollment_date"
									auto-apply
									:format="format"
									:clearable="false"
									:class="errors.enrollment_date ? 'mb-1' : 'mb-6'"
									:state="(errors.enrollment_date) ? !errors.enrollment_date : null"
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2">Request author</div>
							<v-autocomplete
									:items="creators"
									:loading="!creatorsLoaded"
									:disabled="!creatorsLoaded"
									variant="outlined"
									placeholder="Request author"
									v-model="fields.created_by"
									:error-messages="errors.created_by"
							></v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2">Department</div>
							<v-autocomplete
									:items="departments"
									:loading="!departmentsLoaded"
									:disabled="!departmentsLoaded"
									item-title="name"
									item-value="name"
									variant="outlined"
									placeholder="Department"
									v-model="fields.department"
									:error-messages="errors.department"
							></v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2">Expense item</div>
							<v-autocomplete
									:items="expenseItems"
									:loading="!expenseItemsLoaded"
									:disabled="!expenseItemsLoaded"
									item-title="name"
									item-value="name"
									variant="outlined"
									placeholder="Expense item"
									v-model="fields.cashflow_expense_item"
									:return-object="false"
									:error-messages="errors.cashflow_expense_item"
							></v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2">Expense subitem</div>
							<v-autocomplete
									:items="expenseSubItems"
									:loading="!expenseSubItemsLoaded"
									:disabled="!expenseSubItemsLoaded"
									variant="outlined"
									item-title="name"
									item-value="name"
									placeholder="Expense subitem"
									v-model="fields.cashflow_expense_sub_item"
									:return-object="false"
									:error-messages="errors.cashflow_expense_sub_item"
							></v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 3">
							<div class="text-body-1 mb-2">Model username</div>
							<v-autocomplete
									:items="commonListModels"
									:loading="!commonListModelsLoaded"
									:disabled="!commonListModelsLoaded"
									v-model="fields.model_nickname"
									variant="outlined"
									placeholder="Model"
									item-title="nickname"
									item-value="nickname"
									:error-messages="errors.model_nickname"
							></v-autocomplete>
						</v-col>
					</v-row>
				</div>

				<v-divider></v-divider>

				<div class="my-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Payment info</div>
					<v-row class="flex-wrap">
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2">Payment system</div>
							<v-autocomplete
									variant="outlined"
									item-title="name"
									item-value="name"
									placeholder="Payment system"
									:loading="!paymentSystemsLoaded"
									:disabled="!paymentSystemsLoaded"
									:items="paymentSystems"
									v-model="fields.payment_system"
									:return-object="false"
									:error-messages="errors.payment_system"
							></v-autocomplete>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2">Amount</div>
							<v-text-field
									variant="outlined"
									placeholder="Amount"
									v-model="fields.amount"
									:error-messages="errors.amount"
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2">Currency</div>
							<v-autocomplete
									variant="outlined"
									item-title="name"
									item-value="name"
									placeholder="Currency"
									:loading="!currenciesLoaded"
									:disabled="!currenciesLoaded"
									:items="currencies"
									v-model="fields.currency"
									:return-object="false"
									:error-messages="errors.currency"
							></v-autocomplete>
						</v-col>
						<v-col cols="12">
							<div class="text-body-1 mb-2">Payment details</div>
							<v-text-field
									variant="outlined"
									placeholder="Amount"
									v-model="fields.payment_details"
									:error-messages="errors.payment_details"
							></v-text-field>
						</v-col>
						<v-col cols="12">
							<div class="text-body-1 mb-2">Payment description</div>
							<v-textarea
									variant="outlined"
									v-model="fields.payment_description"
									placeholder="Description"
									:error-messages="errors.payment_description"
							></v-textarea>
						</v-col>
					</v-row>
				</div>

				<v-divider></v-divider>

				<div class="my-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Provide detail</div>
					<v-row class="flex-wrap" style="max-width: 700px">
						<v-col cols="12">
							<div class="text-body-1 mb-2">Financier</div>
							<v-autocomplete
									:items="creators"
									:loading="!creatorsLoaded"
									:disabled="!creatorsLoaded"
									variant="outlined"
									placeholder="Financier"
									v-model="fields.financier"
									:error-messages="errors.financier"
							></v-autocomplete>
						</v-col>
					</v-row>
				</div>

			</div>

			<div class="flex-0-0">
				<v-divider></v-divider>
				<v-row justify="start" no-gutters class="pt-4">
					<v-btn class="ml-2" color="primary" @click="handleRequestCreate" :disabled="formLoading" :loading="formLoading">Create</v-btn>
					<v-btn variant="text" @click="$emit('close')">Cancel</v-btn>
				</v-row>
			</div>

		</v-form>
	</v-card>
</template>

<style scoped>

</style>