<script>
import {useDisplay} from "vuetify";
import apiFinance from "@/api/finance";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { mdAndDown } = useDisplay();
		return { mdAndDown }
	},


	name: "WidgetTodayReport",


	data() {
		return {
			heads: ['model', 'future_purchase', 'currency', 'debt_fans', 'debt', 'total'],
			sizes: {
				model: '25',
				future_purchase: '15',
				currency: '15',
				debt_fans: '15',
				debt: '15',
				total: '15',
			},
			sizesMobile: {
				model: '200',
				future_purchase: '150',
				currency: '200',
				debt_fans: '150',
				debt: '100',
				total: '100',
			},
			items: [],
			itemsLoading: false,
			itemsLoaded: false,
			copy: '',
			copyLoading: false,
			copyLoaded: false
		}
	},


	created() {
		this.getTodayReport();
		this.getTodayReportCopy();
	},


	methods: {
		headName(value) {
			if (value === 'model') return 'Model';
			if (value === 'future_purchase') return 'Future<br>purchases';
			if (value === 'currency') return 'Currency,<br>%';
			if (value === 'debt_fans') return 'Debt,<br>fans';
			if (value === 'debt') return 'Debt,<br>$';
			if (value === 'total') return 'Total,<br>$';
		},
		async getTodayReport() {
			try {
				this.itemsLoading = true;
				this.itemsLoaded = false;
				const unsortedList = await apiFinance.getTodayReport();
				this.items = unsortedList.sort((item1, item2) => item1.future_purchase > item2.future_purchase ? -1 : 1);
				this.itemsLoading = false;
				this.itemsLoaded = true
			} catch (error) {
				console.log(error)
			}
		},
		async getTodayReportCopy() {
			try {
				this.copyLoading = true;
				this.copyLoaded = false;
				this.copy = await apiFinance.getTodayReportCopy();
				this.copyLoading = false;
				this.copyLoaded = true
			} catch (error) {
				console.log(error)
			}
		},
		copyTable() {
			window.navigator.clipboard.writeText(this.copy.copy_data)
		}
	},
}
</script>

<template>
	<v-sheet v-if="itemsLoaded" class="rounded-lg px-5 py-4 elevation-1 flex-column d-flex">
		<div class="flex-0-0">
			<v-row align="center">
				<v-col><div class="text-h6">Today report</div></v-col>
				<v-col cols="auto"><v-btn variant="flat" size="small" icon="mdi-content-copy" @click="copyTable"></v-btn></v-col>
			</v-row>
		</div>
		<div class="overflow-y-auto flex-1-1 mt-5 mx-n2 mb-n1">
			<div class="overflow-x-auto fill-height">
				<div class="d-flex pa-2 align-end text-center position-sticky bg-white" :class="[{'w-fit': mdAndDown}]" style="top: 0; z-index: 1">
					<div
							v-for="(item, index) in heads"
							:key="index"
							:style="mdAndDown ? { width: sizesMobile[item] + 'px' } : { width: sizes[item] + '%' }"
							:class="index === 0 ? 'text-left' : ''"
							v-html="headName(item)"
					></div>
				</div>
				<div
						class="d-flex pa-2 rounded text-center"
						:class="[index % 2 ? 'bg-grey-lighten-4' : 'bg-white', {'bg-deep-orange-lighten-2': item.active}, {'w-fit': mdAndDown}]"
						v-for="(item, index) in items"
						:key="index"
				>
					<div :style="mdAndDown ? { width: sizesMobile.model + 'px' } : { width: sizes.model + '%' }" class="text-left text-body-2">{{ item.model }}</div>
					<div :style="mdAndDown ? { width: sizesMobile.future_purchase + 'px' } : { width: sizes.future_purchase + '%' }" class="text-body-2 font-weight-light">{{ item.future_purchase }}</div>
					<div :style="mdAndDown ? { width: sizesMobile.currency + 'px' } : { width: sizes.currency + '%' }" class="text-body-2 font-weight-light">{{ item.currency }}</div>
					<div :style="mdAndDown ? { width: sizesMobile.debt_fans + 'px' } : { width: sizes.debt_fans + '%' }" class="text-body-2 font-weight-light">{{ item.debt_fans }}</div>
					<div :style="mdAndDown ? { width: sizesMobile.debt + 'px' } : { width: sizes.debt + '%' }" class="text-body-2 font-weight-light">{{ item.debt.toLocaleString('ru') }}</div>
					<div :style="mdAndDown ? { width: sizesMobile.total + 'px' } : { width: sizes.total + '%' }" class="text-body-2 font-weight-light">{{ item.total.toLocaleString('ru') }}</div>
				</div>
			</div>
		</div>
	</v-sheet>
	<v-card v-else class="pa-4 fill-height rounded-lg d-flex align-center justify-center">
		<v-progress-circular indeterminate color="primary"></v-progress-circular>
	</v-card>
</template>

<style scoped>

</style>