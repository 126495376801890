<script>
import {useDisplay} from "vuetify";
import {mapState} from "vuex";
import apiAds from "@/api/ads";
import SelectModel from "@/components/SelectModel.vue";
import SelectMarketer from "@/components/SelectMarketer.vue";
import SelectPromo from "@/components/SelectPromo.vue";
import SelectDonor from "@/components/SelectDonor.vue";
import {toast} from "vuetify-sonner";

export default {
	setup () {
		// Destructure only the keys we want to use
		const { lgAndUp, mdAndDown } = useDisplay();
		return { lgAndUp, mdAndDown }
	},


	name: "PromoSwapDialog",


	components: {
		SelectDonor,
		SelectPromo, SelectMarketer,
		SelectModel,
	},


	props: {
		info: {
			type: Object,
		}
	},


	data() {
		return {
			format: 'dd.MM.yyyy',
			formLoading: false,
			fields: {},
			parentFields: {},
			childFields: {},
			errors: {},
			swapLoading: false,
			swapLoaded: false,
		}
	},


	computed: {
		...mapState({
			commonListModels: state => state.user.commonListModels,
			commonListModelsLoaded: state => state.user.commonListModelsLoaded,
		}),
	},


	created() {
		this.setFields()
	},


	methods: {
		formatingDate(date, format) {
			const year = date.getFullYear();
			const month = (date.getMonth() + 1).toString().padStart(2, '0');
			const day = (date.getDate().toString().padStart(2, '0'));
			if (format === 'dd.MM.yyyy') {
				return `${day}.${month}.${year}`;
			} else if (format === 'yyyy-MM-dd') {
				return `${year}-${month}-${day}`;
			}
		},

		setFields() {
			this.fields.trial_link = null;
			this.fields.of_link_date = null;
			this.fields.date = null;
			this.fields.only_fans_model = null;
			this.fields.marketer = null;
			this.fields.promo = null;
			this.fields.donor = this.info.donor;
			this.fields.comment = null;

			this.parentFields.cost = this.info.cost;
			this.parentFields.cwvt = this.info.cwvt;
			this.parentFields.phantom_cost = Number((this.info.cost / this.info.fans_count * this.info.claims_count).toFixed(2));
			this.parentFields.claims_count = this.info.claims_count;
			this.parentFields.fans_count = this.info.claims_count;
			this.parentFields.revenue = this.info.revenue;
			this.parentFields.profit = Number((this.info.revenue - this.parentFields.phantom_cost).toFixed(2));
			this.parentFields.romi = Number((this.parentFields.profit / this.parentFields.phantom_cost * 100).toFixed(2));
			this.parentFields.spenders = this.info.spenders;
			this.parentFields.fans_delta = 0;

			this.childFields.cost = 1;
			this.childFields.cwvt = 1;
			this.childFields.phantom_cost = this.parentFields.cost - this.parentFields.phantom_cost;
			this.childFields.claims_count = 0;
			this.childFields.fans_count = this.info.fans_delta;
			this.childFields.revenue = 0;
			this.childFields.profit = this.childFields.revenue - this.childFields.phantom_cost;
			this.childFields.romi = Number((this.childFields.profit / this.childFields.phantom_cost * 100).toFixed(2));
			this.childFields.spenders = 0;
			this.childFields.fans_delta = this.info.fans_delta;
		},

		async swapPromo() {
			this.errors = {};
			let date = null;
			let of_link_date = null;
			if (this.fields.date) { date = this.formatingDate(this.fields.date, 'yyyy-MM-dd') }
			if (this.fields.of_link_date) { of_link_date = this.formatingDate(this.fields.of_link_date, 'yyyy-MM-dd') }
			const params = {
				trial_link: this.fields.trial_link,
				of_link_date: of_link_date,
				date: date,
				only_fans_model: this.fields.only_fans_model,
				marketer: this.fields.marketer,
				promo: this.fields.promo,
				comment: this.fields.comment,
			};
			try {
				this.swapLoading = true;
				this.swapLoaded = false;
				const response = await apiAds.swapPromo(this.info.id, params);
				this.$emit('swap', response)
				this.$emit('close')
			} catch (error) {
				console.log(error);
				const errors = Object.keys(error.response.data);
				let toastText = '';
				errors.forEach((key) => {
					this.errors[key] = error.response.data[key]
					toastText = toastText.concat(`${key.toUpperCase()}: ${error.response.data[key]} `)
				});
				console.log(toastText)

				toast('Ошибка:', {
					duration: 5000,
					description: `Не удалось выполнить свап. ${toastText}`,
					cardProps: {
						color: 'error',
					},
				});
			} finally {
				this.swapLoading = false;
				this.swapLoaded = true;
			}
		},
	}
}
</script>

<template>
	<v-card class="pa-6">
		<v-form class="fill-height d-flex flex-column" :disabled="swapLoading" @submit.prevent>

			<div class="flex-0-0">
				<v-row no-gutters>
					<v-col>
						<v-btn
								class="d-flex align-center cursor-pointer font-weight-bold"
								prepend-icon="mdi-arrow-left"
								variant="text"
								text="Back"
								@click="$emit('close')"
						></v-btn>
					</v-col>
				</v-row>
				<v-divider class="mt-4 mb-8"></v-divider>
				<v-row justify="space-between">
					<v-col>
						<div class="text-h5 mb-4">
							<span>{{info.date}}</span>
							<span class="font-weight-medium ml-5">{{info.only_fans_model?.nickname}}</span>
						</div>
						<div class="mb-2 d-flex align-center"><span>id: {{ info.ads_number }}</span></div>
					</v-col>
				</v-row>
				<v-divider class="my-6"></v-divider>
			</div>

			<div class="flex-1-1 overflow-y-auto py-4 px-3 mx-n3">
				<div class="mb-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">New link parameters</div>
					<v-row class="flex-wrap">
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">New link</div>
							<v-text-field
									variant="outlined"
									placeholder="New link"
									v-model="fields.trial_link"
									:error-messages="errors.trial_link"
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">OF link Date</div>
							<VueDatePicker
									position="right"
									class="clearable-false"
									:class="errors.of_link_date ? 'mb-1' : 'mb-6'"
									:state="(errors.of_link_date) ? !errors.of_link_date : null"
									v-model="fields.of_link_date"
									auto-apply
									:format="format"
									:clearable="false"
							/>
							<v-slide-y-transition leave-absolute>
								<div class="pl-4 text-error text-caption" v-if="errors.of_link_date" transition="slide-x-transition" style="height: 20px">{{ errors.of_link_date[0] }}</div>
							</v-slide-y-transition>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">Date (new promo)</div>
							<VueDatePicker
									position="left"
									class="clearable-false"
									:class="errors.date ? 'mb-1' : 'mb-6'"
									:state="(errors.date) ? !errors.date : null"
									v-model="fields.date"
									auto-apply
									:format="format"
									:clearable="false"
							/>
							<v-slide-y-transition leave-absolute>
								<div class="pl-4 text-error text-caption" v-if="errors.date" transition="slide-x-transition" style="height: 20px">{{ errors.date[0] }}</div>
							</v-slide-y-transition>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 6">
							<div class="text-body-1 mb-2">New model</div>
							<SelectModel
									v-model="fields.only_fans_model"
									:items="commonListModels"
									:loading="!commonListModelsLoaded"
									:disabled="!commonListModelsLoaded"
									:error-messages="errors.only_fans_model"
							/>
						</v-col>
					</v-row>
				</div>
				<v-divider class="my-6"></v-divider>
				<div class="mb-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Parent promo new metrics</div>
					<v-row class="flex-wrap">
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Cost</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Cost"
									prepend-inner-icon="mdi-currency-usd"
									v-model="parentFields.cost"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Cost without VAT and fee</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Cost without VAT and fee"
									prepend-inner-icon="mdi-currency-usd"
									v-model="parentFields.cwvt"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Phantom cost</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Phantom cost"
									prepend-inner-icon="mdi-currency-usd"
									v-model="parentFields.phantom_cost"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Claims count</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Claims count"
									v-model="parentFields.claims_count"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Fans</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Fans"
									v-model="parentFields.fans_count"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Revenue</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Revenue"
									prepend-inner-icon="mdi-currency-usd"
									v-model="parentFields.revenue"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Profit</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Profit"
									prepend-inner-icon="mdi-currency-usd"
									v-model="parentFields.profit"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">ROMI</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="ROMI"
									prepend-inner-icon="mdi-percent-outline"
									v-model="parentFields.romi"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Spenders</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Spenders"
									v-model="parentFields.spenders"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Fans delta</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Fans delta"
									v-model="parentFields.fans_delta"
									hide-details
							></v-text-field>
						</v-col>
					</v-row>
				</div>
				<v-divider class="my-6"></v-divider>
				<div class="mb-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Child promo metrics</div>
					<v-row class="flex-wrap">
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Cost</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Cost"
									prepend-inner-icon="mdi-currency-usd"
									v-model="childFields.cost"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Cost without VAT and fee</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Cost without VAT and fee"
									prepend-inner-icon="mdi-currency-usd"
									v-model="childFields.cwvt"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Phantom cost</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Phantom cost"
									prepend-inner-icon="mdi-currency-usd"
									v-model="childFields.phantom_cost"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Claims count</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Claims count"
									v-model="childFields.claims_count"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Fans</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Fans"
									v-model="childFields.fans_count"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Revenue</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Revenue"
									prepend-inner-icon="mdi-currency-usd"
									v-model="childFields.revenue"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Profit</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Profit"
									prepend-inner-icon="mdi-currency-usd"
									v-model="childFields.profit"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">ROMI</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="ROMI"
									prepend-inner-icon="mdi-percent-outline"
									v-model="childFields.romi"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Spenders</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Spenders"
									v-model="childFields.spenders"
									hide-details
							></v-text-field>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Fans delta</div>
							<v-text-field
									disabled
									readonly
									variant="outlined"
									placeholder="Fans delta"
									v-model="childFields.fans_delta"
									hide-details
							></v-text-field>
						</v-col>
					</v-row>
				</div>
				<v-divider class="my-6"></v-divider>
				<div class="mb-6">
					<div class="text-subtitle-1 mb-5 font-weight-medium text-grey">Other</div>
					<v-row class="flex-wrap">
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2">Buyer</div>
							<SelectMarketer
									v-model="fields.marketer" clearable
									:error-messages="errors.marketer"
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2">Promo</div>
							<SelectPromo
									v-model="fields.promo"
									clearable
									:error-messages="errors.promo"
							/>
						</v-col>
						<v-col :cols="mdAndDown ? 12 : 4">
							<div class="text-body-1 mb-2 text-grey">Model donor</div>
							<SelectDonor
									disabled
									readonly
									hide-details
									v-model="fields.donor"
							/>
						</v-col>
						<v-col cols="12">
							<div class="text-body-1 mb-2">Comments</div>
							<v-textarea
									variant="outlined"
									v-model="fields.comment"
									placeholder="Some comment here"
							></v-textarea>
						</v-col>
					</v-row>
				</div>
			</div>

			<div class="flex-0-0">
				<v-divider></v-divider>
				<v-row justify="start" no-gutters class="mt-4 pa-0">
					<v-btn class="mr-2" :loading="swapLoading" type="submit" color="primary" @click="swapPromo">Перелить</v-btn>
					<v-btn variant="text" @click="$emit('close')">Отмена</v-btn>
				</v-row>
			</div>

		</v-form>

	</v-card>
</template>

<style scoped>

</style>